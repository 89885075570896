import { 
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_VALIDATION,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAIL,
  USER_SAVE_REQUEST,
  USER_SAVE_SUCCESS,
  USER_SAVE_VALIDATION,
  USER_SAVE_FAIL,
  USER_EDIT_REQUEST,
  USER_EDIT_SUCCESS,
  USER_EDIT_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_VALIDATION,
  USER_UPDATE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL
} from '../constants/authConstants'
import axios from 'axios'

export const login = (email, password) => async (dispatch) => {
  try {

    dispatch({
      type: LOGIN_REQUEST
    })

      const { data } = await axios.post(
        'login',
        {
          'email': email,
          'password': password,
          'device_name': 'web'
        },
      )

      localStorage.setItem('userInfo', JSON.stringify(data))
  
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data
      })
  
      
      window.location.href = process.env.REACT_APP_LINK_REDIRECT
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: LOGIN_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      dispatch({
        type: LOGIN_FAIL,
        payload: 
          e.response && e.response.data.message.message
            ? e.response.data.message.message
            : e.message
      })
    }
  }
}

export const logout = () =>async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT_REQUEST,
    })

    await axios.post('logout')

    localStorage.removeItem('userInfo')
    axios.defaults.headers.common.Authorization = null;

    dispatch({
      type: LOGOUT_SUCCESS,
    })
      
    window.location.href = process.env.REACT_APP_LINK_REDIRECT

  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: LOGOUT_FAIL,
      payload: err          
    })
  }
}

export const Profile = () => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_REQUEST,
    })

    const { data } = await axios.get('profile')

    dispatch({
      type: PROFILE_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: PROFILE_FAIL,
      payload: err          
    })
  }
}

//=================================  Adviser  ============================================

export const userList = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    })

    const { data } = await axios.get(`user`, {
      params: {
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: USER_LIST_FAIL,
      payload: err          
    })
  }
}

export const userSave = (name, roles, email, password, history) => async (dispatch) => {
  try {
    dispatch({
      type: USER_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('role', roles)
    formData.append('email', email)
    formData.append('password', password)

    await axios.post('user',formData,config)

    dispatch({
      type: USER_SAVE_SUCCESS,
    })

    history.push('/user-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: USER_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: USER_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const userEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_EDIT_REQUEST,
    })

    const { data } = await axios.get(`user/${id}/edit`)

    dispatch({
      type: USER_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: USER_EDIT_FAIL,
      payload: err          
    })
  }
}

export const userUpdate = (name, roles, email, password, id, history) => async (dispatch) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const formData = {name, "role": roles, email, password}

    await axios.put(`user/${id}`,formData,config)

    dispatch({
      type: USER_UPDATE_SUCCESS,
    })
    history.push('/user-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: USER_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: USER_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const userDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST
    })

    await axios.delete(`user/${id}`)

    dispatch({
      type: USER_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: USER_DELETE_FAIL,
      payload: err          
    })
  }
}