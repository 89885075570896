import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {

  return (
    <div className="">
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-center align-items-center flex-column" style={{height: "100vh"}}>
            <h1>404 Error</h1>
            <p>this page doesnt exist</p>
            <Link to={"/dashboard"} className="btn btn-info p-2">&#8592; Return to dashboard</Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
