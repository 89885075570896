import { OFFICE_DELETE_FAIL, OFFICE_DELETE_REQUEST, OFFICE_DELETE_SUCCESS, OFFICE_EDIT_FAIL, OFFICE_EDIT_REQUEST, OFFICE_EDIT_SUCCESS, OFFICE_LIST_FAIL, OFFICE_LIST_REQUEST, OFFICE_LIST_SUCCESS, OFFICE_SAVE_FAIL, OFFICE_SAVE_REQUEST, OFFICE_SAVE_SUCCESS, OFFICE_SAVE_VALIDATION, OFFICE_UPDATE_FAIL, OFFICE_UPDATE_REQUEST, OFFICE_UPDATE_SUCCESS, OFFICE_UPDATE_VALIDATION } from '../constants/officeConstants'
import axios from 'axios'

//=================================  Office  ============================================

export const officeList = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: OFFICE_LIST_REQUEST,
    })

    const { data } = await axios.get(`office`, {
      params: {
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: OFFICE_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: OFFICE_LIST_FAIL,
      payload: err          
    })
  }
}

export const officeSave = (name,companyname,email,phone,whatsapp,status,fax,province,city,address,mapltld,description,joindate,closedate,created_by,officearea,ppc, history) => async (dispatch) => {
  try {
    dispatch({
      type: OFFICE_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json"
      },
    };

    const data = {name,companyname,email,phone,whatsapp,"active": status,fax,province,city,address,mapltld,description,joindate,closedate,created_by,officearea,ppc}

    await axios.post('office',data,config)

    dispatch({
      type: OFFICE_SAVE_SUCCESS,
    })

    history.push('/office-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: OFFICE_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: OFFICE_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const officeEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: OFFICE_EDIT_REQUEST,
    })

    const { data } = await axios.get(`office/${id}/edit`)

    dispatch({
      type: OFFICE_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: OFFICE_EDIT_FAIL,
      payload: err          
    })
  }
}

export const officeUpdate = (name,principal,companyname,email,phone,whatsapp,status,fax,province,city,address,mapltld,description,joindate,closedate, updated_by, officearea,ppc, id, history) => async (dispatch) => {
  try {
    dispatch({
      type: OFFICE_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('principal', principal)
    formData.append('name', name)
    formData.append('company_name', companyname)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('whatsapp', whatsapp)
    formData.append('status', status)
    formData.append('fax', fax)
    formData.append('province', province)
    formData.append('city', city)
    formData.append('address', address)
    formData.append('map_latitude_longitude', mapltld)
    formData.append('description', description)
    formData.append('join_date', joindate)
    formData.append('close_date', closedate)
    formData.append('updated_by', updated_by)
    formData.append('office_area', officearea)
    formData.append('ppc', ppc)
    formData.append('_method', 'PUT')

    await axios.post(`office/${id}`,formData,config)

    dispatch({
      type: OFFICE_UPDATE_SUCCESS,
    })
    history.push('/office-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: OFFICE_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: OFFICE_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const officeDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: OFFICE_DELETE_REQUEST
    })

    await axios.delete(`office/${id}`)

    dispatch({
      type: OFFICE_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: OFFICE_DELETE_FAIL,
      payload: err          
    })
  }
}
