import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { csmEdit, csmUpdate ,officeAllList } from '../../../../store/actions/memberActions'
import { Select, Upload, message, Tooltip } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const CSMEdit = () => {
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [phone, setphone] = useState('')
  const [gender, setgender] = useState('')
  const [joindate, setjoindate] = useState('')
  const [status, setstatus] = useState('')
  const [exitdate, setexitdate] = useState('')
  const [password, setpassword] = useState('')
  const [retype_password, setretype_password] = useState('')
  const [id_office, setoffice] = useState('')
  
  const [nda, setNda] = useState();
  const [ndaFile, setNdaFile] = useState();

  const [mcquaig, setMcquaig] = useState();
  const [mcquaigFile, setMcquaigFile] = useState();

  const [pfindo, setPfindo] = useState();
  const [pfindoFile, setPfindoFile] = useState();

  const [ktp, setKtp] = useState();
  const [ktpFile, setKtpFile] = useState();

  let dispatch = useDispatch()
  let history = useHistory()
  const id = localStorage.getItem('id_csm');

  useEffect(() => {
    dispatch(csmEdit(id))
    dispatch(officeAllList())
  }, [dispatch, id])

    useEffect(() => {
          if (!userInfo || !userInfo.permission) return;
    if (userInfo.user.roles[0].name == "Super Admin") return;
      if (!userInfo.permission.includes("csm-edit")) window.location.href = process.env.REACT_APP_LINK_REDIRECT + "403";
    }, []);

      const dataLogin = useSelector((state) => state.login);
      let { userInfo } = dataLogin;

  const datacsmUpdate = useSelector(state => state.csmUpdate)
  const { validation } = datacsmUpdate

  const dataofficeAllList = useSelector(state => state.officeAllList)
  const { listsOffice } = dataofficeAllList

  const datacsmEdit = useSelector(state => state.csmEdit)
  const { csm } = datacsmEdit

  useMemo(() => {
    if (csm) {
      setname(csm.name)
      setemail(csm.email)
      setphone(csm.phone)
      setoffice(csm.id_office)
      setgender(csm.gender)
      setjoindate(csm.join_date)
      setstatus(csm.active)
      setexitdate(csm.exit_date)
      setpassword('')
      setretype_password('')

      setNda(process.env.REACT_APP_IMAGE_LINK + csm.nda)
      setPfindo(process.env.REACT_APP_IMAGE_LINK + csm.pfindo)
      setKtp(process.env.REACT_APP_IMAGE_LINK + csm.ktp)
      setMcquaig(process.env.REACT_APP_IMAGE_LINK + csm.mcquaig)
    }
  }, [csm])

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(csmUpdate(name, email, phone, gender, joindate, exitdate, status,password,retype_password,id_office, id, history, ndaFile, ktpFile, mcquaigFile, pfindoFile))
  }
  
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function base64ToBlob(base64, type) {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteArrays = [];
    
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }
    
    const byteArray = new Uint8Array(byteArrays);
    return new Blob([byteArray], { type });
  }

  const handleChangeNDA = (info) => {
    setNdaFile(info.file.originFileObj)
    getBase64(info.file.originFileObj, imageUrl => {
      setNda(imageUrl)
    }
      // setloading(false)
    );
  };

  const handleChangeMcquaig = (info) => {
    setMcquaigFile(info.file.originFileObj)
    getBase64(info.file.originFileObj, imageUrl =>
      setMcquaig(imageUrl),
      // setloading(false)
    );
  };

  const handleChangePfindo = (info) => {
    setPfindoFile(info.file.originFileObj)
    getBase64(info.file.originFileObj, imageUrl =>
      setPfindo(imageUrl),
      // setloading(false)
    );
  };

  const handleChangeKtp = (info) => {
    setKtpFile(info.file.originFileObj)
    getBase64(info.file.originFileObj, imageUrl =>
      setKtp(imageUrl),
      // setloading(false)
    );
  };

  const generateUrl = (x) => {
    if(x?.startsWith("data:image")){
      return URL.createObjectURL(base64ToBlob(x,'image/png'))
    }
    if(x?.startsWith("data:application/pdf")){
      return URL.createObjectURL(base64ToBlob(x,'application/pdf'))
    }
    return x
  }

  const beforeUpload = (file) => {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
    
    if (!allowedTypes.includes(file.type)) {
      message.error('Only JPG, JPEG, PNG, and PDF files are allowed!');
      return Upload.LIST_IGNORE;
    }
  
    return true;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );


  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Edit csm</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-12 col-sm-6 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content">  
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Name</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setname(e.target.value)}
                        value={name}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['name']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Phone</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['phone'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setphone(e.target.value)}
                        value={phone}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['phone']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  
                  <div className="col-md-4 col-sm-4 col-xs-12">
                    <div className="form-group d-flex flex-column">
                      <label for="name">Office</label>
                      <Select
                        showSearch
                        placeholder="Select an office"
                        optionFilterProp="children"
                        onChange={(value) => setoffice(value)}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={listsOffice}
                        value={id_office}
                      />
                      {
                            validation.length !== 0 
                            ?
                              <ul className="parsley-errors-list filled">
                                <li className="parsley-required">{validation['id_office']}</li>
                              </ul>
                            :
                              null
                          }
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-4 col-xs-12">
                    <div className="form-group">
                      <label for="name">Gender</label>
                      <select 
                        className={ validation.length !== 0 && validation['gender'] ? 'form-control parsley-error' : 'form-control' }
                        required
                        onChange={(e) => setgender(e.target.value)}
                        defaultValue={gender}
                        value={gender}
                      >
                        <option disabled selected value="">Select Gender</option>
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                      </select>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['gender']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-4 col-xs-12">
                    <div className="form-group">
                      <label for="name">Email</label>
                      <input type="email" 
                        className={ validation.length !== 0 && validation['email'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setemail(e.target.value)}
                        value={email}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['email']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="form-group">
                        <label for="name">Password</label>
                        <input type="password" 
                          className={ validation.length !== 0 && validation['password'] ? 'form-control parsley-error' : 'form-control' }
                           
                          onChange={(e) => setpassword(e.target.value)}
                          value={password}
                        />
                        {
                          validation.length !== 0 
                          ?
                            <ul className="parsley-errors-list filled">
                              <li className="parsley-required">{validation['password']}</li>
                            </ul>
                          :
                            null
                        }
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-xs-12">
                      <div className="form-group">
                        <label for="name">Retype Password</label>
                        <input type="password" 
                          className={ validation.length !== 0 && validation['retype_password'] ? 'form-control parsley-error' : 'form-control' }
                           
                          onChange={(e) => setretype_password(e.target.value)}
                          value={retype_password}
                        />
                        {
                          validation.length !== 0 
                          ?
                            <ul className="parsley-errors-list filled">
                              <li className="parsley-required">{validation['retype_password']}</li>
                            </ul>
                          :
                            null
                        }
                      </div>
                    </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Join Date</label>
                      <input type="date" 
                        className={ validation.length !== 0 && validation['join_date'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setjoindate(e.target.value)}
                        value={joindate}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['join_date']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Exit Date</label>
                      <input type="date" 
                        className={ validation.length !== 0 && validation['exit_date'] ? 'form-control parsley-error' : 'form-control' }
                         
                        onChange={(e) => setexitdate(e.target.value)}
                        value={exitdate}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['exit_date']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label for="name">Status</label>
                  <select 
                    className={ validation.length !== 0 && validation['status'] ? 'form-control parsley-error' : 'form-control' }
                    required
                    onChange={(e) => setstatus(e.target.value)}
                    defaultValue={status}
                    value={status}
                  >
                    <option disabled selected value="">Select Status</option>
                    <option value="1">Active</option>
                    <option value="0">Deactive</option>
                  </select>
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['status']}</li>
                      </ul>
                    :
                      null
                  }
                </div>

                <div className="row">
                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <label>NDA</label>
                    <Tooltip 
                      title={nda?.slice(-4) !== "null" ?
                        <a href={generateUrl(nda)} target="_blank">
                          <img src={nda?.startsWith("data:application/pdf") || nda?.endsWith(".pdf") ? "https://www.greenfoothills.org/wp-content/uploads/2017/11/PDF-icon-1.png" : nda} alt="preview" style={{ width: 200 }} />
                        </a> :
                        null} 
                      placement="top"
                    >
                      <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={handleChangeNDA}
                          >
                            {nda?.slice(-4) !== "null" ? 
                              <img src={nda?.startsWith("data:application/pdf") || nda?.endsWith(".pdf") ? "https://www.greenfoothills.org/wp-content/uploads/2017/11/PDF-icon-1.png" : nda} alt="avatar" style={{ width: '90%' }} /> 
                              : 
                              uploadButton
                            }
                      </Upload>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['nda']}</li>
                          </ul>
                        :
                          null
                      }
                    </Tooltip>
                  </div>

                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <label>Mcquaig Test</label>
                    <Tooltip 
                      title={mcquaig?.slice(-4) !== "null" ?
                        <a href={generateUrl(mcquaig)} target="_blank">
                          <img src={mcquaig?.startsWith("data:application/pdf") || mcquaig?.endsWith(".pdf") ? "https://www.greenfoothills.org/wp-content/uploads/2017/11/PDF-icon-1.png" : mcquaig} alt="preview" style={{ width: 200 }} />
                        </a> :
                        null} 
                      placement="top"
                    >
                      <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          onChange={handleChangeMcquaig}
                          beforeUpload={beforeUpload}
                        >
                          {mcquaig?.slice(-4) !== "null" ? <img src={mcquaig?.startsWith("data:application/pdf") || mcquaig?.endsWith(".pdf") ? "https://www.greenfoothills.org/wp-content/uploads/2017/11/PDF-icon-1.png" : mcquaig} alt="avatar" style={{ width: '90%' }} /> : uploadButton}
                      </Upload>
                        {
                          validation.length !== 0 
                          ?
                            <ul className="parsley-errors-list filled">
                              <li className="parsley-required">{validation['mcquaig']}</li>
                            </ul>
                          :
                            null
                        }
                      </Tooltip>
                  </div>

                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <label>Pefindo Score</label>
                    <Tooltip 
                      title={pfindo?.slice(-4) !== "null" ?
                        <a href={generateUrl(pfindo)} target="_blank">
                          <img src={pfindo?.startsWith("data:application/pdf") || pfindo?.endsWith(".pdf") ? "https://www.greenfoothills.org/wp-content/uploads/2017/11/PDF-icon-1.png" : pfindo} alt="preview" style={{ width: 200 }} />
                        </a> :
                        null} 
                      placement="top"
                    >
                      <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            onChange={handleChangePfindo}
                            beforeUpload={beforeUpload}
                          >
                            {pfindo?.slice(-4) !== "null" ? <img src={pfindo?.startsWith("data:application/pdf") || pfindo?.endsWith(".pdf") ? "https://www.greenfoothills.org/wp-content/uploads/2017/11/PDF-icon-1.png" : pfindo} alt="avatar" style={{ width: '90%' }} /> : uploadButton}
                      </Upload>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['pfindo']}</li>
                          </ul>
                        :
                          null
                      }
                    </Tooltip>
                  </div>

                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <label>KTP</label>
                    <Tooltip 
                      title={ktp?.slice(-4) !== "null" ?
                        <a href={generateUrl(ktp)} target="_blank">
                          <img src={ktp?.startsWith("data:application/pdf") || ktp?.endsWith(".pdf") ? "https://www.greenfoothills.org/wp-content/uploads/2017/11/PDF-icon-1.png" : ktp} alt="preview" style={{ width: 200 }} />
                        </a> :
                        null} 
                      placement="top"
                    >
                      <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          onChange={handleChangeKtp}
                          beforeUpload={beforeUpload}
                        >
                          {ktp?.slice(-4) !== "null" ? <img src={ktp?.startsWith("data:application/pdf") || ktp?.endsWith(".pdf") ? "https://www.greenfoothills.org/wp-content/uploads/2017/11/PDF-icon-1.png" : ktp} alt="avatar" style={{ width: '90%' }} /> : uploadButton}
                      </Upload>
                        {
                          validation.length !== 0 
                          ?
                            <ul className="parsley-errors-list filled">
                              <li className="parsley-required">{validation['ktp']}</li>
                            </ul>
                          :
                            null
                        }
                      </Tooltip>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CSMEdit
