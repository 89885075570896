import { useQueryParams } from "./useQueryParams";
import { useDispatch } from "react-redux";
import { leadWebsiteList } from "../store/actions/leadWebsiteActions";

export function useFilter() {
  const { setQueryParam } = useQueryParams();
  const dispatch = useDispatch();
  const leadWebsiteFilter = (search, statusId, officeId, mitraId) => {
    if (statusId) {
      setQueryParam("status", statusId);
      dispatch(leadWebsiteList(1, search, statusId));
    } else if (officeId) {
      setQueryParam("office_id", officeId);
      dispatch(leadWebsiteList(1, search, null, officeId));
    } else if (mitraId) {
      setQueryParam("mitra_id", mitraId);
      dispatch(leadWebsiteList(1, search, null, null, mitraId));
    } else if (statusId && officeId && mitraId) {
      setQueryParam("status", statusId);
      setQueryParam("office_id", officeId);
      setQueryParam("mitra_id", mitraId);
      dispatch(leadWebsiteList(1, search, statusId, officeId, mitraId));
    }
  };

  return {
    leadWebsiteFilter,
  };
}
