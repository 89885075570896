import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { mitraSave, bankList } from '../../../store/actions/mitraActions'
import { officeAllList } from '../../../store/actions/memberActions'
import { Select } from 'antd';

const MitraCreate = () => {
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [phone, setphone] = useState('')
  const [joindate, setjoindate] = useState('')
  const [status, setstatus] = useState('')
  const [bank, setbank] = useState([])
  const [office, setoffice] = useState([])

  let dispatch = useDispatch()
  let history = useHistory()

  useEffect(() => {
    dispatch(bankList())
    dispatch(officeAllList())
  }, [dispatch])

    useEffect(() => {
          if (!userInfo || !userInfo.permission) return;
    if (userInfo.user.roles[0].name == "Super Admin") return;
      if (!userInfo.permission.includes("partners-create")) window.location.href = process.env.REACT_APP_LINK_REDIRECT + "403";
    }, []);

  const dataofficeAllList = useSelector(state => state.officeAllList)
  const { listsOffice } = dataofficeAllList

  const datamitraSave = useSelector(state => state.mitraSave)
  const { validation } = datamitraSave

  const dataLogin = useSelector(state => state.login)
  const { userInfo } = dataLogin
  const created_by = userInfo.user.id

  const databankList = useSelector(state => state.bankList)
  const{ listsBank } = databankList

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(mitraSave(name, email, phone, joindate, status, bank, office, created_by, history))
  }

  const handleChange = selectedItems => {
    setbank(selectedItems);
  }

  const handleChangeOffice = selectedItems => {
    setoffice(selectedItems);
  }

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Create Patrner</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content overflow-auto">  
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Name</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setname(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['name']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Email</label>
                      <input type="email" 
                        className={ validation.length !== 0 && validation['email'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setemail(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['email']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Phone</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['phone'] ? 'form-control parsley-error' : 'form-control' }
                        required 
                        onChange={(e) => setphone(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['phone']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Join Date</label>
                      <input type="date" 
                        className={ validation.length !== 0 && validation['join_date'] ? 'form-control parsley-error' : 'form-control' }
                        name="name" 
                        required 
                        onChange={(e) => setjoindate(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['join_date']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Status</label>
                      <select 
                        className={ validation.length !== 0 && validation['status'] ? 'form-control parsley-error' : 'form-control' }
                        required
                        onChange={(e) => setstatus(e.target.value)}
                      >
                        <option disabled selected value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Deactive</option>
                      </select>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['status']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                  </div>
                </div>
                <div className="form-group">
                  <label for="name">Bank</label>
                  <Select
                    mode="multiple"
                    value={bank}
                    size='large'
                    onChange={handleChange}
                    style={{ width: '100%' }}
                    options={listsBank}
                    optionFilterProp='label'
                  >
                  </Select>
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['bank']}</li>
                      </ul>
                    :
                      null
                  }
                </div>
                <div className="form-group">
                  <label for="name">Office</label>
                  <Select
                    mode="multiple"
                    value={office}
                    size='large'
                    onChange={handleChangeOffice}
                    style={{ width: '100%' }}
                    options={listsOffice}
                    optionFilterProp='label'
                  >
                  </Select>
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['office']}</li>
                      </ul>
                    :
                      null
                  }
                </div>
                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MitraCreate
