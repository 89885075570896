import { CITY_BY_PARENT_LIST_FAIL, CITY_BY_PARENT_LIST_REQUEST, CITY_BY_PARENT_LIST_SUCCESS, PROVINCE_LIST_FAIL, PROVINCE_LIST_REQUEST, PROVINCE_LIST_SUCCESS, OFFICE_AREA_LIST_REQUEST, OFFICE_AREA_LIST_SUCCESS, OFFICE_AREA_LIST_FAIL } from '../constants/lookupConstants'
import axios from 'axios'

//=================================  Province  ============================================

export const provinceList = () => async (dispatch) => {
  try {
    dispatch({
      type: PROVINCE_LIST_REQUEST,
    })

    const { data } = await axios.get(`lookup/listsProvince`)

    dispatch({
      type: PROVINCE_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: PROVINCE_LIST_FAIL,
      payload: err          
    })
  }
}

//=================================  City  ============================================

export const citybyprovinceList = (province) => async (dispatch) => {
  try {
    dispatch({
      type: CITY_BY_PARENT_LIST_REQUEST,
    })

    const { data } = await axios.get(`lookup/listsCityByProvince/${province}`)

    dispatch({
      type: CITY_BY_PARENT_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: CITY_BY_PARENT_LIST_FAIL,
      payload: err          
    })
  }
}

//=================================  Office Area  ============================================

export const officeareaList = () => async (dispatch) => {
  try {
    dispatch({
      type: OFFICE_AREA_LIST_REQUEST,
    })

    const { data } = await axios.get(`lookup/listsofficearea`)

    dispatch({
      type: OFFICE_AREA_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: OFFICE_AREA_LIST_FAIL,
      payload: err          
    })
  }
}