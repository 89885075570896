import { combineReducers } from 'redux'
import { loginReducer, profileReducer, userListReducer, userSaveReducer, userEditReducer, userUpdateReducer, userDeleteReducer } from './authReducers'
import { parentDeleteReducer, parentEditReducer, parentListReducer, parentSaveReducer, parentUpdateReducer, childListReducer, childSaveReducer, childEditReducer, childUpdateReducer, childDeleteReducer, parentListByAccessReducer, menuListReducer, roleMYListReducer, roleMYSaveReducer, roleMYEditReducer, roleMYUpdateReducer, roleMYDeleteReducer, roleMYCRMListReducer, roleMYCRMSaveReducer, roleMYCRMEditReducer, roleMYCRMUpdateReducer, roleMYCRMDeleteReducer, parentAllListReducer, roleAllListReducer, } from './menuReducers'
import { adminbranchDeleteReducer, adminbranchEditReducer, adminbranchListReducer, adminbranchSaveReducer, adminbranchUpdateReducer, adminDeleteReducer, adminEditReducer, adminListReducer, adminSaveReducer, adminUpdateReducer, adviserAllListReducer, adviserDeleteReducer, adviserEditReducer, adviserListReducer, adviserSaveReducer, adviserUpdateReducer, officeAllListReducer, principalbyofficeListReducer, principalDeleteReducer, principalEditReducer, principalListReducer, principalSaveReducer, principalUpdateReducer,csmDeleteReducer, csmEditReducer, csmListReducer, csmSaveReducer, csmUpdateReducer, } from './memberReducers'
import { officeDeleteReducer, officeEditReducer, officeListReducer, officeSaveReducer, officeUpdateReducer } from './officeReducers'
import { citybyprovinceListReducer, provinceListReducer, officeareaListReducer } from './lookupReducers'
import { careerDeleteReducer, careerEditReducer, careerListReducer, careerSaveReducer, careerUpdateReducer, newsDeleteReducer, newsEditReducer, newsListReducer, newsSaveReducer, newsUpdateReducer, partnerDeleteReducer, partnerEditReducer, partnerListReducer, partnerSaveReducer, partnerUpdateReducer, topperformerDeleteReducer, topperformerEditReducer, topperformerListReducer, topperformerSaveReducer, topperformerUpdateReducer } from './websiteReducers'
import { bankListReducer, mitraCreateTokenReducer, mitraDeleteReducer, mitraDeleteTokenReducer, mitraDetailReducer, mitraEditReducer, mitraListReducer, mitraSaveReducer, mitraUpdateReducer } from './mitraReducers'
import { developerDeleteReducer, developerEditReducer,developerAllReducer, developerListReducer, developerSaveReducer, developerUpdateReducer } from './developerReducers'
import { developeremployeeDeleteReducer, developeremployeeEditReducer, developeremployeeListReducer, developeremployeeSaveReducer, developeremployeeUpdateReducer } from './developeremployeeReducers'
import { assetPromoCategoryReducer, promoCategoriesReducer, promoReducer } from './promoReducers'
import { leadWebsiteApplyOfficeReducer, leadWebsiteBulkChangeStatusReducer, leadWebsiteBulkOfficeApplyReducer, leadWebsiteEditReducer, leadWebsiteEditStatusReducer, leadWebsiteOfficeReducer, leadWebsiteReducer, leadWebsiteRemarksReducer, leadWebsiteUpdateReducer } from './leadWebsiteReducers'
import { whatappReducer } from './whatsappReducers'

const allReducers = combineReducers({
  login: loginReducer,
  profile: profileReducer,
  userList: userListReducer,
  userSave: userSaveReducer,
  userEdit: userEditReducer,
  userUpdate: userUpdateReducer,
  userDelete: userDeleteReducer,

  parentList: parentListReducer,
  parentListByAccess: parentListByAccessReducer,
  parentAllList: parentAllListReducer,
  parentSave: parentSaveReducer,
  parentEdit: parentEditReducer,
  parentUpdate: parentUpdateReducer,
  parentDelete: parentDeleteReducer,
  childList: childListReducer,
  childSave: childSaveReducer,
  childEdit: childEditReducer,
  childUpdate: childUpdateReducer,
  childDelete: childDeleteReducer,
  roleMYList: roleMYListReducer,
  roleMYSave: roleMYSaveReducer,
  roleMYEdit: roleMYEditReducer,
  roleMYUpdate: roleMYUpdateReducer,
  roleMYDelete: roleMYDeleteReducer,
  roleMYCRMList: roleMYCRMListReducer,
  roleMYCRMSave: roleMYCRMSaveReducer,
  roleMYCRMEdit: roleMYCRMEditReducer,
  roleMYCRMUpdate: roleMYCRMUpdateReducer,
  roleMYCRMDelete: roleMYCRMDeleteReducer,
  menuList: menuListReducer,
  roleAllList: roleAllListReducer,

  officeAllList: officeAllListReducer,
  adviserAllList: adviserAllListReducer,
  adviserList: adviserListReducer,
  adviserSave: adviserSaveReducer,
  adviserEdit: adviserEditReducer,
  adviserUpdate: adviserUpdateReducer,
  adviserDelete: adviserDeleteReducer,
  principalList: principalListReducer,
  principalbyofficeList: principalbyofficeListReducer,
  principalSave: principalSaveReducer,
  principalEdit: principalEditReducer,
  principalUpdate: principalUpdateReducer,
  principalDelete: principalDeleteReducer,
  adminList: adminListReducer,
  adminSave: adminSaveReducer,
  adminEdit: adminEditReducer,
  adminUpdate: adminUpdateReducer,
  adminDelete: adminDeleteReducer,
  csmList: csmListReducer,
  csmSave: csmSaveReducer,
  csmEdit: csmEditReducer,
  csmUpdate: csmUpdateReducer,
  csmDelete: csmDeleteReducer,
  adminbranchList: adminbranchListReducer,
  adminbranchSave: adminbranchSaveReducer,
  adminbranchEdit: adminbranchEditReducer,
  adminbranchUpdate: adminbranchUpdateReducer,
  adminbranchDelete: adminbranchDeleteReducer,
  
  officeList: officeListReducer,
  officeSave: officeSaveReducer,
  officeEdit: officeEditReducer,
  officeUpdate: officeUpdateReducer,
  officeDelete: officeDeleteReducer,

  newsList: newsListReducer,
  newsSave: newsSaveReducer,
  newsEdit: newsEditReducer,
  newsUpdate: newsUpdateReducer,
  newsDelete: newsDeleteReducer,
  partnerList: partnerListReducer,
  partnerSave: partnerSaveReducer,
  partnerEdit: partnerEditReducer,
  partnerUpdate: partnerUpdateReducer,
  partnerDelete: partnerDeleteReducer,
  topperformerList: topperformerListReducer,
  topperformerSave: topperformerSaveReducer,
  topperformerEdit: topperformerEditReducer,
  topperformerUpdate: topperformerUpdateReducer,
  topperformerDelete: topperformerDeleteReducer,
  careerList: careerListReducer,
  careerSave: careerSaveReducer,
  careerEdit: careerEditReducer,
  careerUpdate: careerUpdateReducer,
  careerDelete: careerDeleteReducer,

  bankList: bankListReducer,
  mitraList: mitraListReducer,
  mitraSave: mitraSaveReducer,
  mitraEdit: mitraEditReducer,
  mitraUpdate: mitraUpdateReducer,
  mitraDelete: mitraDeleteReducer,
  mitraDetail: mitraDetailReducer,
  mitraCreateToken: mitraCreateTokenReducer,
  mitraDeleteToken: mitraDeleteTokenReducer,

  developerList: developerListReducer,
  developerAll: developerAllReducer,
  developerSave: developerSaveReducer,
  developerEdit: developerEditReducer,
  developerUpdate: developerUpdateReducer,
  developerDelete: developerDeleteReducer,

  
  developeremployeeList: developeremployeeListReducer,
  developeremployeeSave: developeremployeeSaveReducer,
  developeremployeeEdit: developeremployeeEditReducer,
  developeremployeeUpdate: developeremployeeUpdateReducer,
  developeremployeeDelete: developeremployeeDeleteReducer,

  provinceList: provinceListReducer,
  citybyprovinceList: citybyprovinceListReducer,
  officeareaList: officeareaListReducer,
  
  promo: promoReducer,
  promoCategories: promoCategoriesReducer,
  assetPromoCategory: assetPromoCategoryReducer,

  leadWebsite: leadWebsiteReducer,
  leadWebsiteEdit: leadWebsiteEditReducer,
  leadWebsiteUpdate: leadWebsiteUpdateReducer,
  leadWebsiteOffice: leadWebsiteOfficeReducer,
  leadWebsiteRemarks: leadWebsiteRemarksReducer,
  leadWebsiteApplyOffice: leadWebsiteApplyOfficeReducer,
  leadWebsiteBulkOfficeApply: leadWebsiteBulkOfficeApplyReducer,
  leadWebsiteBulkChangeStatus: leadWebsiteBulkChangeStatusReducer,
  leadWebsiteEditStatus: leadWebsiteEditStatusReducer,

  whatsapp: whatappReducer
})

export default allReducers