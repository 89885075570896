import { Drawer, Spin } from "antd";
import { useState, useEffect, useRef, useCallback } from "react";
import echo from "../../utils/echo";
import { useDispatch, useSelector } from "react-redux";
import { clearMessages, getMessages, getNextMessages, handleWebSocket, newMessage } from "../../store/actions/whatsappActions";

const FromThemText = ({text, timestamp}) => {   //component untuk text dari WA
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginTop: "10px",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: "rgba(245, 245, 245)",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          whiteSpace: "pre-line"
        }}
      >
        <span className="message-content" style={{marginRight: "10px", flex: "1 1 auto"}}>{text}</span>
        <small style={{textAlign: "right", color: "gray", flexShrink: 0, marginLeft: "auto", marginTop: "auto"}}>{timestamp}</small>
      </div>
    </div>
  )
}

const FromMeText = ({text, timestamp}) => { //component untuk text dari kita
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginTop: "10px",
        justifyContent: "flex-end",
      }}
    >
      <div
        style={{
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: "rgba(220, 248, 198)",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          whiteSpace: "pre-line"
        }}
      >
        <span className="message-content" style={{marginRight: "10px", flex: "1 1 auto"}}>{text}</span>
        <small style={{textAlign: "right", color: "gray", flexShrink: 0, marginLeft: "auto", marginTop: "auto"}}>{timestamp}</small>
      </div>
    </div>
  );
}

const DocumentMessage = ({text, timestamp}) => { //component untuk text dari WA yang berformat document
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginTop: "10px",
        width: "100%",
      }}
    >
        <div style={{
          height: "130px", 
          display: "flex", 
          alignItems: "end", 
          borderRadius: "10px", 
          backgroundImage: "url(https://pnghq.com/wp-content/uploads/pdf-logo-png-png-no-watermark-41801-300x296.png)",
          backgroundSize: "auto 80px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: 'center',
          backgroundColor: "rgba(245, 245, 245)",
          width: "100%",
          }}>
          <div
            style={{
              backgroundColor: "rgba(235, 235, 235)",
              padding: "10px",
              borderRadius: "10px",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              width: "100%",
            }}
          >
            <a href="#" className="message-content" style={{marginRight: "10px", flex: "1 1 auto"}}>{text} <i className="fa fa-download" aria-hidden="true"></i></a>
            <small style={{textAlign: "right", color: "gray", flexShrink: 0, marginLeft: "auto"}}>{timestamp}</small>
          </div>
        </div>
    </div>
  )
}

const WhatsappDrawer = ({whatsAppDrawer, setWhatsappDrawer, phoneNumber, chatName}) => {
  const dispatch = useDispatch()
  const dataWA = useSelector(state => state.whatsapp)
  const {messages, loading, next} = dataWA
  const loadingRef = useRef(false);
  const nextRef = useRef();
  const isOpened = useRef(false);
  const phoneNumRef = useRef("");
  const chatRef = useRef(null);
  const scrollPosition = useRef(null);
  const skipAutoScroll = useRef(false);
  const [newMessageState, setNewMessageState] = useState("");

  useEffect(() => {nextRef.current = next}, [next])
  useEffect(() => {phoneNumRef.current = phoneNumber}, [phoneNumber])
  useEffect(() => {loadingRef.current = loading}, [loading])

  const handleNewMessage = async (e) => {  //api request untuk messsage baru
    e.preventDefault();

    const x = newMessageState
    skipAutoScroll.current = false;
    setNewMessageState("")
    dispatch(newMessage(x, phoneNumber));
  }

  useEffect(() => {     //web socket connection
    const channel = echo.channel("Whatsapp")
    .listen('WhatsappMessage', (x) => {
      const phoneNum = x.data.data[0].changes?.[0]?.value.contacts[0].wa_id;
      skipAutoScroll.current = true;
      dispatch(handleWebSocket(x.data, phoneNum, phoneNumRef.current , isOpened.current));
    })

    return () => {
      channel.stopListening('WhatsappMessage');
      echo.leaveChannel('Whatsapp');
    };
  }, []);

  useEffect(() => {       
    if(whatsAppDrawer){
      skipAutoScroll.current = false;
      dispatch(getMessages(phoneNumber)) //saat whatsapp drawer terbuka laksanakan api request

      const chatElement = chatRef.current;
      if (chatElement) {
        chatElement.addEventListener("scroll", handleScroll); //saat whatsapp drawer terbuka add event listener buat scroll
      }

      isOpened.current = true;
    }else {
      chatRef?.current?.removeEventListener("scroll", handleScroll);
      dispatch(clearMessages());
      isOpened.current = false;
    }
  }, [whatsAppDrawer]);

  useEffect(() => {
    if(!chatRef) return;
    if (chatRef.current && !skipAutoScroll.current) {    //auto scroll kebawah saat messages berubah
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }else if(skipAutoScroll.current){
      chatRef.current.scrollTop = chatRef.current.scrollHeight - scrollPosition.current;
    }

    const hasScrollbar = chatRef.current?.scrollHeight > chatRef.current?.clientHeight;

    if(!hasScrollbar && nextRef.current !== null && !loadingRef.current) {
      dispatch(getNextMessages(nextRef.current, phoneNumRef.current));
    }
  }, [messages]);

  const handleScroll = useCallback(() => {
    //function untuk handle scroll
    if (!chatRef.current) return;
    if (chatRef.current.scrollTop === 0 && !loadingRef.current && nextRef.current !== null) {
      scrollPosition.current = chatRef.current.scrollHeight - chatRef.current.scrollTop;
      skipAutoScroll.current = true;
      dispatch(getNextMessages(nextRef.current, phoneNumRef.current));
    }
  }, [])

  const closeWhatsappDrawer = () => {   //bersihin state saat drawer ditutup
    phoneNumber = 0;
    setWhatsappDrawer(false);
  };

  return (
    <Drawer
      title="Whatsapp"
      onClose={closeWhatsappDrawer}
      open={whatsAppDrawer}
      style={{
        padding: "0px"
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
          padding: "0px"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            backgroundColor: "#25d366",
            padding: "10px",
            borderRadius: "10px",
            width: "100%",
          }}
        >
          <i
            className="fa fa-whatsapp text-white"
            style={{ fontSize: "45px" }}
          ></i>
          <span
            style={{ fontSize: "20px", fontWeight: "bold", color: "white" }}
          >
            {chatName}
          </span>
        </div>

        <div style={{ height: "100%", width: "100%", overflowY: "scroll"}} ref={chatRef}>
          <div style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
          }}>{loading ? <Spin size="large" style={{position: "fixed"}} /> : ""}</div>
        
          {
              messages.map((element, key) => {
                  Date.prototype.toString = function () {
                      return this.toLocaleString(); // Automatically uses local format
                  };

                  const formattedTime = new Date(element.created_at)
                  const x = formattedTime.toString().split(" ");
                  const y = x[1].split(":");
                  const timestamp = y[0]+":"+y[1]+" "+x[2]

                  switch(element.data?.[0]?.changes?.[0]?.value?.messages?.[0].type){
                     case "document":
                          // console.log("oiiii ada image/document nih");
                          if(messages?.[key]?.created_at?.split(" ")[0].split("-")[2] !== messages?.[key - 1]?.created_at.split(" ")[0].split("-")[2] && messages?.[key - 1] !== undefined){
                            return [
                            <div style={{width: "80%", border: "solid lightgray 1px", marginTop: "10px", marginRight: "20px", marginLeft: "20px"}} key={`serperator-${key}`}></div>,
                            <p style={{width: "100%", textAlign: "center", color: "gray", marginBottom: "10px"}} key={`date-${key}`}>{element?.created_at?.split(" ")[0]}</p>,

                            <DocumentMessage key={key} text={element.data?.[0]?.changes?.[0]?.value?.messages?.[0].document.filename} timestamp={timestamp} />
                          ]
                        }
      
                        return <DocumentMessage key={key} text={element.data?.[0]?.changes?.[0]?.value?.messages?.[0].document.filename} timestamp={timestamp} />
                     case "text":
                        // console.log("message b aja");
      
                        if(messages?.[key]?.created_at?.split(" ")[0].split("-")[2] !== messages?.[key - 1]?.created_at.split(" ")[0].split("-")[2] && messages?.[key - 1] !== undefined){
                            return [
                            <div style={{width: "80%", border: "solid lightgray 1px", marginTop: "10px", marginRight: "20px", marginLeft: "20px"}} key={`serperator-${key}`}></div>,
                            <p style={{width: "100%", textAlign: "center", color: "gray", marginBottom: "10px"}} key={`date-${key}`}>{element?.created_at?.split(" ")[0]}</p>,

                            <FromThemText key={key} text={element.data?.[0]?.changes?.[0]?.value?.messages?.[0].text.body} timestamp={timestamp} />
                          ]
                        }
      
                        return <FromThemText key={key} text={element.data?.[0]?.changes?.[0]?.value?.messages?.[0].text.body} timestamp={timestamp} />
                      default: 
                        try {
                          if(messages?.[key]?.created_at?.split(" ")[0].split("-")[2] !== messages?.[key - 1]?.created_at.split(" ")[0].split("-")[2] && messages?.[key - 1] !== undefined){
                            return [
                            <div style={{width: "80%", border: "solid lightgray 1px", marginTop: "10px", marginRight: "20px", marginLeft: "20px"}} key={`serperator-${key}`}></div>,
                            <p style={{width: "100%", textAlign: "center", color: "gray", marginBottom: "10px"}} key={`date-${key}`}>{element?.created_at?.split(" ")[0]}</p>,
                            <FromMeText key={key} text={element.data.text.body} timestamp={timestamp} />
                            ]
                          }
                          return <FromMeText key={key} text={element.data.text.body} timestamp={timestamp} />
                        }catch(e){
                          console.log("ada statuses: ", element);
                        }
                        // console.log("from me");
                  }
              })
          }

          {
            messages.length === 0 && !loading ? <h2 style={{width: "100%", textAlign: "center"}}>No Messages Yet!</h2> : ""
          }

        </div>

        <div style={{ width: "100%" }}>
          <form onSubmit={handleNewMessage} style={{ width: "100%", display: "flex", gap: "10px", paddingTop: "10px" }}>
            <textarea
              type="text"
              style={{ width: "90%", padding: "10px", borderRadius: "10px", border: "none", backgroundColor: "rgba(235, 235, 235)", resize: "none"}}
              placeholder="Type a message"
              onChange={(e) => setNewMessageState(e.target.value)} 
              value={newMessageState}
              rows={1}
            />
            <button
              style={{
                width: "10%",
                padding: "10px",
                borderRadius: "10px",
                backgroundColor: "#25d366",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
              }}
            >
              <i className="fa fa-paper-plane" aria-hidden="true"></i>
            </button>
          </form>
        </div>
      </div>
    </Drawer>
  );
};




export default WhatsappDrawer;
