import { Empty } from "antd";

const TableWrapper = (props) => <Table {...props} />;

const Table = (props) => {
  const {
    tableHead,
    tableBody,
    handleCheckedAll,
    isCheckedAll,
    datas,
  } = props;

  return (
    <table className="table table-striped table-hover">
      <thead className="text-nowrap">
        <tr>
          {tableHead.map((header, index) => (
            <th key={index} style={{ textAlign: header.name === "Action" || header.name === "Status" ? "center" : "left"}}>
                {header.checkbox && (
                  <div className="d-flex align-items-center">
                      <input
                        style={{ margin: "0px" }}
                        type="checkbox"
                        onChange={handleCheckedAll}
                        checked={isCheckedAll}
                      />
                    {header.checkbox && <span style={{ marginLeft: "10px" }} />}
                    {header.name} 
                  </div>
                )}
                {header.name !== "All" && header.name} 
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {datas?.length < 1 ? (
          <tr>
            <th colSpan={tableHead.length}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </th>
          </tr>
        ) : (
          datas?.map((item, index) => (
            <tr className="text-nowrap" key={item.id}>
              {tableBody.map((column, colIndex) => {
                if (column.scope === "row") {
                  return (
                    <th key={colIndex} scope="row">
                      {parseInt(index) + 1}
                    </th>
                  )
                }

                if (column.component) {
                  return (
                    <td key={colIndex} align={column.align}>
                      {column.component(item)}
                    </td>
                  );
                }
                return <td key={colIndex}>{column.render(item)}</td>;
              })}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export default TableWrapper;
