import { message } from 'antd'
import { ADMIN_BRANCH_DELETE_FAIL, ADMIN_BRANCH_DELETE_REQUEST, ADMIN_BRANCH_DELETE_SUCCESS, ADMIN_BRANCH_EDIT_FAIL, ADMIN_BRANCH_EDIT_REQUEST, ADMIN_BRANCH_EDIT_SUCCESS, ADMIN_BRANCH_LIST_FAIL, ADMIN_BRANCH_LIST_REQUEST, ADMIN_BRANCH_LIST_SUCCESS, ADMIN_BRANCH_SAVE_FAIL, ADMIN_BRANCH_SAVE_REQUEST, ADMIN_BRANCH_SAVE_SUCCESS, ADMIN_BRANCH_SAVE_VALIDATION, ADMIN_BRANCH_UPDATE_FAIL, ADMIN_BRANCH_UPDATE_REQUEST, ADMIN_BRANCH_UPDATE_SUCCESS, ADMIN_BRANCH_UPDATE_VALIDATION, ADMIN_DELETE_FAIL, ADMIN_DELETE_REQUEST, ADMIN_DELETE_SUCCESS, ADMIN_EDIT_FAIL, ADMIN_EDIT_REQUEST, ADMIN_EDIT_SUCCESS, ADMIN_LIST_FAIL, ADMIN_LIST_REQUEST, ADMIN_LIST_SUCCESS, ADMIN_SAVE_FAIL, ADMIN_SAVE_REQUEST, ADMIN_SAVE_SUCCESS, ADMIN_SAVE_VALIDATION, ADMIN_UPDATE_FAIL, ADMIN_UPDATE_REQUEST, ADMIN_UPDATE_SUCCESS, ADMIN_UPDATE_VALIDATION, ADVISER_DELETE_FAIL, ADVISER_DELETE_REQUEST, ADVISER_DELETE_SUCCESS, ADVISER_EDIT_FAIL, ADVISER_EDIT_REQUEST, ADVISER_EDIT_SUCCESS, ADVISER_LIST_FAIL, ADVISER_LIST_REQUEST, ADVISER_LIST_SUCCESS, ADVISER_SAVE_FAIL, ADVISER_SAVE_REQUEST, ADVISER_SAVE_SUCCESS, ADVISER_SAVE_VALIDATION, ADVISER_UPDATE_FAIL, ADVISER_UPDATE_REQUEST, ADVISER_UPDATE_SUCCESS, ADVISER_UPDATE_VALIDATION, OFFICEALL_LIST_FAIL, OFFICEALL_LIST_REQUEST, OFFICEALL_LIST_SUCCESS, PRINCIPAL_BY_OFFICE_LIST_FAIL, PRINCIPAL_BY_OFFICE_LIST_REQUEST, PRINCIPAL_BY_OFFICE_LIST_SUCCESS, PRINCIPAL_DELETE_FAIL, PRINCIPAL_DELETE_REQUEST, PRINCIPAL_DELETE_SUCCESS, PRINCIPAL_EDIT_FAIL, PRINCIPAL_EDIT_REQUEST, PRINCIPAL_EDIT_SUCCESS, PRINCIPAL_LIST_FAIL, PRINCIPAL_LIST_REQUEST, PRINCIPAL_LIST_SUCCESS, PRINCIPAL_SAVE_FAIL, PRINCIPAL_SAVE_REQUEST, PRINCIPAL_SAVE_SUCCESS, PRINCIPAL_SAVE_VALIDATION, PRINCIPAL_UPDATE_FAIL, PRINCIPAL_UPDATE_REQUEST, PRINCIPAL_UPDATE_SUCCESS, PRINCIPAL_UPDATE_VALIDATION, ADVISERALL_LIST_REQUEST,
  ADVISERALL_LIST_SUCCESS,
  ADVISERALL_LIST_FAIL,CSM_DELETE_FAIL, CSM_DELETE_REQUEST, CSM_DELETE_SUCCESS, CSM_EDIT_FAIL, CSM_EDIT_REQUEST, CSM_EDIT_SUCCESS, CSM_LIST_FAIL, CSM_LIST_REQUEST, CSM_LIST_SUCCESS, CSM_SAVE_FAIL, CSM_SAVE_REQUEST, CSM_SAVE_SUCCESS, CSM_SAVE_VALIDATION, CSM_UPDATE_FAIL, CSM_UPDATE_REQUEST, CSM_UPDATE_SUCCESS, CSM_UPDATE_VALIDATION, } from '../constants/memberConstants'
import axios from 'axios'

export const officeAllList = () => async (dispatch) => {
  try {
    dispatch({
      type: OFFICEALL_LIST_REQUEST,
    })

    const { data } = await axios.get(`office/lists/all`)

    dispatch({
      type: OFFICEALL_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: OFFICEALL_LIST_FAIL,
      payload: err          
    })
  }
}

export const adviserAllList = () => async (dispatch) => {
  try {
    dispatch({
      type: ADVISERALL_LIST_REQUEST,
    })

    const { data } = await axios.get(`adviser/lists/all`)

    dispatch({
      type: ADVISERALL_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ADVISERALL_LIST_FAIL,
      payload: err          
    })
  }
}

//=================================  Adviser  ============================================

export const adviserList = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: ADVISER_LIST_REQUEST,
    })

    const { data } = await axios.get(`adviser`, {
      params: {
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: ADVISER_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ADVISER_LIST_FAIL,
      payload: err          
    })
  }
}

export const adviserSave = (isLoanSpesialis, name, email, office, phone, gender, joindate, status, isnpwp, issalary, haired, created_by, history, nik, province, city, address, fax, whatsapp, officeArea, password, ndaFile, ktpFile, mcquaigFile, pfindoFile) => async (dispatch) => {
  try {
    if (!isLoanSpesialis) {
      dispatch({
        type: ADVISER_SAVE_REQUEST
      })
  
      const config = { 
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
  
      const formData = new FormData()
      formData.append('name', name)
      formData.append('email', email)
      formData.append('nik', nik)
      formData.append('office', office)
      formData.append('phone', phone)
      formData.append('gender', gender)
      formData.append('join_date', joindate)
      formData.append('status', status)
      formData.append('isnpwp', isnpwp)
      formData.append('issalary', issalary)
      formData.append('haired', haired)
      formData.append('created_by', created_by)
      formData.append('nda', ndaFile)
      formData.append('mcquaig', mcquaigFile)
      formData.append('pfindo', pfindoFile)
      formData.append('ktp', ktpFile)
  
      await axios.post('adviser',formData,config)
  
      dispatch({
        type: ADVISER_SAVE_SUCCESS,
      })
  
      history.push('/adviser-index')
    } else {
      dispatch({
        type: ADVISER_SAVE_REQUEST
      })
  
      const config = { 
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
  
      const formData = new FormData()
      formData.append('name', name)
      formData.append('email', email)
      formData.append('nik', nik)
      formData.append('phone', phone)
      formData.append('gender', gender)
      formData.append('join_date', joindate)
      formData.append('status', status)
      formData.append('isnpwp', isnpwp)
      formData.append('issalary', issalary)
      formData.append('haired', haired)
      formData.append('created_by', created_by)

      formData.append('is_loan_specialist', isLoanSpesialis ? 1 : 0);
      formData.append('province', province);
      formData.append('city', city);
      formData.append('address', address);
      formData.append('fax', fax);
      formData.append('whatsapp', whatsapp);
      formData.append('officeArea', officeArea);
      formData.append('password', password); 
      
  
      await axios.post('adviser',formData,config)
  
      dispatch({
        type: ADVISER_SAVE_SUCCESS,
      })
  
      history.push('/adviser-index')
    }
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: ADVISER_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: ADVISER_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const adviserEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ADVISER_EDIT_REQUEST,
    })

    const { data } = await axios.get(`adviser/${id}/edit`)

    dispatch({
      type: ADVISER_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ADVISER_EDIT_FAIL,
      payload: err          
    })
  }
}

export const adviserUpdate = (name, email, office, phone, gender, joindate, status, isnpwp, issalary, haired, updated_by, id, history, nik = '', verified, ndaFile, pfindoFile, ktpFile, mcquaigFile) => async (dispatch) => {
  try {
    dispatch({
      type: ADVISER_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    if (nik) {
      formData.append('nik', nik)
    }
    formData.append('office', office)
    formData.append('phone', phone)
    formData.append('gender', gender)
    formData.append('join_date', joindate)
    formData.append('status', status)
    formData.append('isnpwp', isnpwp)
    formData.append('issalary', issalary)
    formData.append('haired', haired)
    formData.append('is_verified', verified)
    formData.append('updated_by', updated_by)
    formData.append('nda', ndaFile)
    formData.append('mcquaig', mcquaigFile)
    formData.append('pfindo', pfindoFile)
    formData.append('ktp', ktpFile)
    formData.append('_method', 'PUT')
 
    await axios.post(`adviser/${id}`,formData,config)

    dispatch({
      type: ADVISER_UPDATE_SUCCESS,
    })
    history.push('/adviser-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: ADVISER_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: ADVISER_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const adviserDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ADVISER_DELETE_REQUEST
    })

    await axios.delete(`adviser/${id}`)

    dispatch({
      type: ADVISER_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ADVISER_DELETE_FAIL,
      payload: err          
    })
  }
}

export const adviserBlacklist = async (id, blacklistReason) => {
  const response = await axios.post(`adviser/${id}/blacklist`, {
    reason: blacklistReason,
  });
  return response;
}

//=================================  Principal  ============================================

export const principalList = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: PRINCIPAL_LIST_REQUEST,
    })

    const { data } = await axios.get(`principal`, {
      params: {
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: PRINCIPAL_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: PRINCIPAL_LIST_FAIL,
      payload: err          
    })
  }
}

export const principalbyofficeList = (office) => async (dispatch) => {
  try {
    dispatch({
      type: PRINCIPAL_BY_OFFICE_LIST_REQUEST,
    })

    const { data } = await axios.get(`principal/by/office/${office}`)

    dispatch({
      type: PRINCIPAL_BY_OFFICE_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: PRINCIPAL_BY_OFFICE_LIST_FAIL,
      payload: err          
    })
  }
}

export const principalSave = (name, email, office, phone, gender, joindate, status, isnpwp, issalary, haired, created_by, history, ndaFile, ktpFile, mcquaigFile, pfindoFile) => async (dispatch) => {
  try {
    dispatch({
      type: PRINCIPAL_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    formData.append('office', office)
    formData.append('phone', phone)
    formData.append('gender', gender)
    formData.append('join_date', joindate)
    formData.append('status', status)
    formData.append('isnpwp', isnpwp)
    formData.append('issalary', issalary)
    formData.append('haired', haired)
    formData.append('created_by', created_by)
    formData.append('nda', ndaFile)
    formData.append('ktp', ktpFile)
    formData.append('pfindo', pfindoFile)
    formData.append('mcquaig', mcquaigFile)


    await axios.post('principal',formData,config)

    dispatch({
      type: PRINCIPAL_SAVE_SUCCESS,
    })

    history.push('/principal-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: PRINCIPAL_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: PRINCIPAL_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const principalEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PRINCIPAL_EDIT_REQUEST,
    })

    const { data } = await axios.get(`principal/${id}/edit`)

    dispatch({
      type: PRINCIPAL_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: PRINCIPAL_EDIT_FAIL,
      payload: err          
    })
  }
}

export const principalUpdate = (name, email, office, phone, gender, joindate, status, isnpwp, issalary, haired, updated_by, id, history, verified, ndaFile, ktpFile, mcquaigFile, pfindoFile) => async (dispatch) => {
  try {
    dispatch({
      type: PRINCIPAL_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    formData.append('office', office)
    formData.append('phone', phone)
    formData.append('gender', gender)
    formData.append('join_date', joindate)
    formData.append('status', status)
    formData.append('isnpwp', isnpwp)
    formData.append('issalary', issalary)
    formData.append('haired', haired)
    formData.append('is_verified', verified)
    formData.append('updated_by', updated_by)
    formData.append('_method', 'PUT')
    formData.append('nda', ndaFile)
    formData.append('ktp', ktpFile)
    formData.append('pfindo', pfindoFile)
    formData.append('mcquaig', mcquaigFile)

    await axios.post(`principal/${id}`,formData,config)

    dispatch({
      type: PRINCIPAL_UPDATE_SUCCESS,
    })
    history.push('/principal-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: PRINCIPAL_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: PRINCIPAL_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const principalDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PRINCIPAL_DELETE_REQUEST
    })

    await axios.delete(`principal/${id}`)

    dispatch({
      type: PRINCIPAL_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: PRINCIPAL_DELETE_FAIL,
      payload: err          
    })
  }
}

//=================================  Admin  ============================================

export const adminList = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_LIST_REQUEST,
    })

    const { data } = await axios.get(`admin`, {
      params: {
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: ADMIN_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ADMIN_LIST_FAIL,
      payload: err          
    })
  }
}

export const adminSave = (name, email, phone, gender, joindate, status, created_by, password, retypePassword, history, ndaFile, ktpFile, mcquaigFile, pfindoFile) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('gender', gender)
    formData.append('join_date', joindate)
    formData.append('status', status)
    formData.append('created_by', created_by)
    formData.append('password', password)
    formData.append('password_confirmation', retypePassword)
    formData.append('nda', ndaFile)
    formData.append('ktp', ktpFile)
    formData.append('pfindo', pfindoFile)
    formData.append('mcquaig', mcquaigFile)

    await axios.post('admin',formData,config)

    dispatch({
      type: ADMIN_SAVE_SUCCESS,
    })

    history.push('/admin-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: ADMIN_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: ADMIN_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const adminEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_EDIT_REQUEST,
    })

    const { data } = await axios.get(`admin/${id}/edit`)

    dispatch({
      type: ADMIN_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ADMIN_EDIT_FAIL,
      payload: err          
    })
  }
}

export const adminUpdate = (name, email, phone, gender, joindate, exitdate, status, updated_by, id, history, ndaFile, ktpFile, mcquaigFile, pfindoFile) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('gender', gender)
    formData.append('join_date', joindate)
    formData.append('exit_date', exitdate)
    formData.append('status', status)
    formData.append('updated_by', updated_by)
    formData.append('_method', 'PUT')
    formData.append('nda', ndaFile)
    formData.append('ktp', ktpFile)
    formData.append('pfindo', pfindoFile)
    formData.append('mcquaig', mcquaigFile)

    await axios.post(`admin/${id}`,formData,config)

    dispatch({
      type: ADMIN_UPDATE_SUCCESS,
    })
    history.push('/admin-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: ADMIN_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: ADMIN_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const adminDelete = (id) => async (dispatch) => {
  try { 
    dispatch({
      type: ADMIN_DELETE_REQUEST
    })

    await axios.delete(`admin/${id}`)

    dispatch({
      type: ADMIN_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ADMIN_DELETE_FAIL,
      payload: err          
    })
  }
}

//=================================  CSM  ============================================

export const csmList = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: CSM_LIST_REQUEST,
    })

    const { data } = await axios.get(`csm`, {
      params: {
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: CSM_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: CSM_LIST_FAIL,
      payload: err          
    })
  }
}

export const csmSave = (name, email, phone, gender, joindate,password,retype_password,id_office, history, ndaFile, ktpFile, mcquaigFile, pfindoFile) => async (dispatch) => {
  try {
    dispatch({
      type: CSM_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('gender', gender)
    formData.append('join_date', joindate)
    formData.append('password', password)
    formData.append('id_office', id_office)
    formData.append('retype_password', retype_password)
    formData.append('nda', ndaFile)
    formData.append('ktp', ktpFile)
    formData.append('pfindo', pfindoFile)
    formData.append('mcquaig', mcquaigFile)

    await axios.post('csm',formData,config)

    dispatch({
      type: CSM_SAVE_SUCCESS,
    })

    history.push('/csm-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: CSM_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: CSM_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const csmEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CSM_EDIT_REQUEST,
    })

    const { data } = await axios.get(`csm/${id}/edit`)

    dispatch({
      type: CSM_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: CSM_EDIT_FAIL,
      payload: err          
    })
  }
}

export const csmUpdate = (name, email, phone, gender, joindate, exitdate, status,password,retype_password,id_office, id, history, ndaFile, ktpFile, mcquaigFile, pfindoFile) => async (dispatch) => {
  try {
    dispatch({
      type: CSM_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('_method', 'PUT')
    formData.append('name', name)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('gender', gender)
    formData.append('join_date', joindate)
    formData.append('exit_date', exitdate)
    formData.append('status', status)
    formData.append('password', password)
    formData.append('id_office', id_office)
    formData.append('retype_password', retype_password)
    formData.append('nda', ndaFile)
    formData.append('ktp', ktpFile)
    formData.append('pfindo', pfindoFile)
    formData.append('mcquaig', mcquaigFile)

    await axios.post(`csm/${id}`,formData,config)

    dispatch({
      type: CSM_UPDATE_SUCCESS,
    })
    history.push('/csm-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: CSM_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: CSM_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const csmDelete = (id) => async (dispatch) => {
  try { 
    dispatch({
      type: CSM_DELETE_REQUEST
    })

    await axios.delete(`csm/${id}`)

    dispatch({
      type: CSM_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: CSM_DELETE_FAIL,
      payload: err          
    })
  }
}
//=================================  Admin Branch  ============================================

export const adminbranchList = (page, search) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_BRANCH_LIST_REQUEST,
    })

    const { data } = await axios.get(`admin-branch`, {
      params: {
        'page' : page,
        'search': search
      }
    })

    dispatch({
      type: ADMIN_BRANCH_LIST_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ADMIN_BRANCH_LIST_FAIL,
      payload: err          
    })
  }
}

export const adminbranchSave = (name, email, office, phone, gender, joindate, status, created_by, history, ndaFile, ktpFile, mcquaigFile, pfindoFile) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_BRANCH_SAVE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    formData.append('office', office)
    formData.append('phone', phone)
    formData.append('gender', gender)
    formData.append('join_date', joindate)
    formData.append('status', status)
    formData.append('created_by', created_by)
    formData.append('nda', ndaFile)
    formData.append('ktp', ktpFile)
    formData.append('pfindo', pfindoFile)
    formData.append('mcquaig', mcquaigFile)

    await axios.post('admin-branch',formData,config)

    dispatch({
      type: ADMIN_BRANCH_SAVE_SUCCESS,
    })

    history.push('/admin-branch-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: ADMIN_BRANCH_SAVE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: ADMIN_BRANCH_SAVE_FAIL,
        payload: err          
      })
    }
  }
}

export const adminbranchEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_BRANCH_EDIT_REQUEST,
    })

    const { data } = await axios.get(`admin-branch/${id}/edit`)

    dispatch({
      type: ADMIN_BRANCH_EDIT_SUCCESS,
      payload: data
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ADMIN_BRANCH_EDIT_FAIL,
      payload: err          
    })
  }
}

export const adminbranchUpdate = (name, email, office, phone, gender, joindate, exitdate, status, updated_by, id, history, ndaFile, ktpFile, mcquaigFile, pfindoFile) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_BRANCH_UPDATE_REQUEST
    })

    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    formData.append('office', office)
    formData.append('phone', phone)
    formData.append('gender', gender)
    formData.append('join_date', joindate)
    formData.append('exit_date', exitdate)
    formData.append('status', status)
    formData.append('updated_by', updated_by)
    formData.append('nda', ndaFile)
    formData.append('ktp', ktpFile)
    formData.append('pfindo', pfindoFile)
    formData.append('mcquaig', mcquaigFile)
    formData.append('_method', 'PUT')

    await axios.post(`admin-branch/${id}`,formData,config)

    dispatch({
      type: ADMIN_BRANCH_UPDATE_SUCCESS,
    })
    history.push('/admin-branch-index')
    
  } catch (e) {
    if (e.response.status === 422) {
      dispatch({
        type: ADMIN_BRANCH_UPDATE_VALIDATION,
        payload: e.response.data.errors
      })
    } else {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: ADMIN_BRANCH_UPDATE_FAIL,
        payload: err          
      })
    }
  }
}

export const adminbranchDelete = (id) => async (dispatch) => {
  try { 
    dispatch({
      type: ADMIN_BRANCH_DELETE_REQUEST
    })

    await axios.delete(`admin-branch/${id}`)

    dispatch({
      type: ADMIN_BRANCH_DELETE_SUCCESS
    })
  } catch (e) {
    const err = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: ADMIN_BRANCH_DELETE_FAIL,
      payload: err          
    })
  }
}