import axios from "axios";
import { message } from 'antd'
import {
  LEAD_WEBSITE_BULK_CHANGE_STATUS_FAIL,
  LEAD_WEBSITE_BULK_CHANGE_STATUS_REQUEST,
  LEAD_WEBSITE_BULK_CHANGE_STATUS_SUCCESS,
  LEAD_WEBSITE_BULK_OFFICE_APPLY_FAIL,
  LEAD_WEBSITE_BULK_OFFICE_APPLY_REQUEST,
  LEAD_WEBSITE_BULK_OFFICE_APPLY_SUCCESS,
  LEAD_WEBSITE_DELETE_FAIL,
  LEAD_WEBSITE_DELETE_REQUEST,
  LEAD_WEBSITE_DELETE_SUCCESS,
  LEAD_WEBSITE_EDIT_FAIL,
  LEAD_WEBSITE_EDIT_REQUEST,
  LEAD_WEBSITE_EDIT_SUCCESS,
  LEAD_WEBSITE_LIST_FAIL,
  LEAD_WEBSITE_LIST_REQUEST,
  LEAD_WEBSITE_LIST_SUCCESS,
  LEAD_WEBSITE_OFFICE_APPLY_FAIL,
  LEAD_WEBSITE_OFFICE_APPLY_REQUEST,
  LEAD_WEBSITE_OFFICE_APPLY_SUCCESS,
  LEAD_WEBSITE_OFFICE_FAIL,
  LEAD_WEBSITE_OFFICE_REQUEST,
  LEAD_WEBSITE_OFFICE_SUCCESS,
  LEAD_WEBSITE_REMARKS_FAIL,
  LEAD_WEBSITE_REMARKS_REQUEST,
  LEAD_WEBSITE_REMARKS_SUCCESS,
  LEAD_WEBSITE_UPDATE_FAIL,
  LEAD_WEBSITE_UPDATE_REQUEST,
  LEAD_WEBSITE_UPDATE_STATUS_FAIL,
  LEAD_WEBSITE_UPDATE_STATUS_REQUEST,
  LEAD_WEBSITE_UPDATE_STATUS_SUCCESS,
  LEAD_WEBSITE_UPDATE_SUCCESS,
  LEAD_WEBSITE_UPDATE_VALIDATION,
  LEAD_WEBSITE_BULK_DELETE_REQUEST,
  LEAD_WEBSITE_BULK_DELETE_SUCCESS,
  LEAD_WEBSITE_BULK_DELETE_FAIL
} from "../constants/leadWebsiteConstants";

//=================================  Leadwebsite  ============================================

export const leadWebsiteList = (page, search, status, officeId, mitraId) => async (dispatch) => {
  try {
    dispatch({
      type: LEAD_WEBSITE_LIST_REQUEST,
    });

    const { data } = await axios.get(`lead-website`, {
      params: {
        page: page,
        search: search,
        status: status,
        office_id: officeId,
        mitra_id: mitraId
      },
    });

    const { data : office } = await axios.get('lead-website/office')
    const { data : mitra } = await axios.get('lead-website/mitra')

    dispatch({
      type: LEAD_WEBSITE_LIST_SUCCESS,
      payload: {
        lists: data,
        office: office,
        mitra: mitra
      },
    });
    
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: LEAD_WEBSITE_LIST_FAIL,
      payload: err,
    });
  }
};

export const leadWebsiteSave = (data) => async (dispatch) => {
  try{
    message.loading('Loading...', "updatable")
    await axios.post("lead-website", data)
    message.success('successfully added to Leads!', "updatable")
  }catch (e){
    message.error('failed to add to Leads!', "updatable");
  }
}

export const leadWebsiteOffice = () => async (dispatch) => {
  try {
    dispatch({
      type: LEAD_WEBSITE_OFFICE_REQUEST
    })

    const { data } = await axios.get(`lead-website/office`)

    dispatch({
      type: LEAD_WEBSITE_OFFICE_SUCCESS,
      payload: data
    })
    
  } catch (error) {
    dispatch({
      type: LEAD_WEBSITE_OFFICE_FAIL,
      payload: error
    })
  }
}

export const leadWebsiteOfficeEdit = (id, officeId) => async (dispatch) => {
  try {
    dispatch({
      type: LEAD_WEBSITE_OFFICE_APPLY_REQUEST
    })

    const { data } = await axios.post(`lead-website/${id}/assign-office`, {
      office_id: officeId
    })

    dispatch({
      type: LEAD_WEBSITE_OFFICE_APPLY_SUCCESS,
      payload: data
    })
    
  } catch (error) {
    dispatch({
      type: LEAD_WEBSITE_OFFICE_APPLY_FAIL,
      payload: error.response.data.message
    })
  }
}

export const leadWebsiteBulkChangeStatus = (id, status) => async (dispatch) => {
  try {
    dispatch({
      type: LEAD_WEBSITE_BULK_CHANGE_STATUS_REQUEST
    })

    const { data } = await axios.post('lead-website/change-status', {
      id: id,
      status: status
    })

    dispatch({
      type: LEAD_WEBSITE_BULK_CHANGE_STATUS_SUCCESS,
      payload: data
    })

  }catch(e) {
    const error = e.response && e.response.data.message ? e.response.data.message : e.message;
    dispatch({
      type: LEAD_WEBSITE_BULK_CHANGE_STATUS_FAIL,
      payload: error
    })
  }
}
export const leadWebsiteBulkOfficeApply = (id, officeId) => async (dispatch) => {
  try {
      dispatch({
        type: LEAD_WEBSITE_BULK_OFFICE_APPLY_REQUEST
      })

      const {data} = await axios.post(`lead-website/assign-office`, {
        id: id,
        office_id: officeId
      })

      dispatch({
        type: LEAD_WEBSITE_BULK_OFFICE_APPLY_SUCCESS,
        payload: data
      })

  } catch (e) {
      const err = e.response && e.response.data.message ? e.response.data.message : e.message;
      dispatch({
        type: LEAD_WEBSITE_BULK_OFFICE_APPLY_FAIL,
        payload: err
      })
  }
}

export const leadWebsiteDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: LEAD_WEBSITE_DELETE_REQUEST,
    });

    await axios.delete(`lead-website/${id}`);

    dispatch({
      type: LEAD_WEBSITE_DELETE_SUCCESS,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: LEAD_WEBSITE_DELETE_FAIL,
      payload: err,
    });
  }
};

export const leadWebsiteBulkDelete = (id) => async (dispatch) => {
  try {
    dispatch({
      type: LEAD_WEBSITE_BULK_DELETE_REQUEST,
    });

    await axios.delete(`lead-website/bulk-delete`, {
      data: {id: id},
    });

    dispatch({
      type: LEAD_WEBSITE_BULK_DELETE_SUCCESS,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: LEAD_WEBSITE_BULK_DELETE_FAIL,
      payload: err,
    });
  }
}



export const leadWebsiteUpdateStatus = (id, status) => async (dispatch) => {
  try {
    dispatch({
      type: LEAD_WEBSITE_UPDATE_STATUS_REQUEST,
    });

    const { data } = await axios.post(`lead-website/${id}/change-status`, {
      status: status,
    });

    dispatch({
      type: LEAD_WEBSITE_UPDATE_STATUS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: LEAD_WEBSITE_UPDATE_STATUS_FAIL,
      payload: err,
    });
  }
};

export const leadWebsiteEdit = (id) => async (dispatch) => {
  try {
    dispatch({
      type: LEAD_WEBSITE_EDIT_REQUEST,
    });

    const { data } = await axios.get(`lead-website/${id}`);

    dispatch({
      type: LEAD_WEBSITE_EDIT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    const err =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    dispatch({
      type: LEAD_WEBSITE_EDIT_FAIL,
      payload: err,
    });
  }
};

export const leadWebsiteRemarks = (id, remarks) => async (dispatch) => {
  try {
    dispatch({
      type: LEAD_WEBSITE_REMARKS_REQUEST,
    });

    const { data } = await axios.put(`lead-website/${id}/remarks`, {
      remarks: remarks,
    })

    dispatch({
      type: LEAD_WEBSITE_REMARKS_SUCCESS,
      payload: data
    });
  } catch (e) {
    const err = e?.response?.data?.message ? e.response.data.message : e.message;
    dispatch({
      type: LEAD_WEBSITE_REMARKS_FAIL,
      payload: err
    })
  }
};

export const leadWebsiteUpdate = (data, id, history) => async (dispatch) => {
    try {
      dispatch({
        type: LEAD_WEBSITE_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.put(`lead-website/${id}`, data, config);

      dispatch({
        type: LEAD_WEBSITE_UPDATE_SUCCESS,
      });
      history.push("/leads-website-index");
    } catch (e) {
      if (e.response.status === 422) {
        dispatch({
          type: LEAD_WEBSITE_UPDATE_VALIDATION,
          payload: e.response.data.errors,
        });
      } else {
        const err =
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message;
        dispatch({
          type: LEAD_WEBSITE_UPDATE_FAIL,
          payload: err,
        });
      }
    }
  };
