import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popconfirm, message, Empty, Modal, Input } from "antd";
import {
  asyncGetAllPromoCategories,
  asyncAddPromoCategories,
  asyncDeletePromoCategories,
  asyncEditPromoCategories,
} from "../../../store/actions/promoActions";
import TableWrapper from "../../../components/Tables";
import Pagination from "../../../components/Pagination";

function PromoCategory() {
  const [pageNumber, setPageNumber] = useState(1);
  const [promoCategoryName, setPromoCategoryName] = useState("");
  const [editedPromoCategoryName, setEditedPromoCategoryName] = useState("");
  const [editedId, setEditedId] = useState("");
  const [showModalAddCategory, setShowModalCategory] = useState(false);
  const [showModalEditCategory, setEditModalCategory] = useState(false);

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncGetAllPromoCategories(pageNumber));
  }, [dispatch]);

  const dataPromoCategories = useSelector((state) => state.promoCategories);
  console.log(dataPromoCategories);

  const dataLogin = useSelector((state) => state.login);
  let { userInfo } = dataLogin;

  const handleAddPromoCategory = async () => {
    await dispatch(asyncAddPromoCategories(promoCategoryName, pageNumber));
    setShowModalCategory(false);
    setPromoCategoryName("");
  };

  const handleShowModalEditPromoCategory = (id, name) => {
    setEditedPromoCategoryName(name);
    setPromoCategoryName(name);
    setEditedId(id);
    setEditModalCategory(true);
  };

  const handleEditPromoCategory = async () => {
    await dispatch(
      asyncEditPromoCategories(editedId, promoCategoryName, pageNumber)
    );
    setEditModalCategory(false);
    setEditedPromoCategoryName("");
    setPromoCategoryName("");
    setEditedId("");
  };

  const tableHead = [
    { name: "Id Category" },
    { name: "Category Name" },
    { name: "Publish Time" },
    { name: "Action" },
  ];

  const tableBody = [
    { render: (item) => item.id },
    { render: (item) => item.title },
    {
      render: (item) =>
        new Date(item.created_at).toLocaleString("en-US", {
          timeZone: "Asia/Jakarta",
        }),
    },
    {
      render: (item) => (
        <div align="center">
          {userInfo.user.roles[0].name === "Super Admin" ? (
            <button
              onClick={() =>
                handleShowModalEditPromoCategory(item.id, item.title)
              }
              className="btn btn-warning btn-sm"
            >
              <i className="fa fa-pencil"></i> Edit{" "}
            </button>
          ) : null}
          {userInfo.user.roles[0].name === "Super Admin" ? (
            <Popconfirm
              title="Are you sure to delete this promo category?"
              onConfirm={() =>
                dispatch(asyncDeletePromoCategories(item.id, pageNumber))
              }
              onCancel={() => message.info("Canceled!")}
              okText="Yes"
              cancelText="No"
            >
              <button className="btn btn-danger btn-sm">
                <i className="fa fa-trash-o"></i> Delete{" "}
              </button>
            </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Promo Category</h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <br />
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  {userInfo.user.roles[0].name === "Super Admin" ? (
                    <button
                      onClick={() => setShowModalCategory(true)}
                      className="btn btn-info btn-sm"
                    >
                      Create New Category
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="clearfix"></div>
            </div>

            <div className="x_content overflow-auto">
              <TableWrapper
                tableHead={tableHead}
                tableBody={tableBody}
                datas={dataPromoCategories.data}
              />
              <Pagination
                datas={dataPromoCategories}
                listsData={asyncGetAllPromoCategories}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Add Promo Category"
        okText={"Submit"}
        open={showModalAddCategory}
        onCancel={() => setShowModalCategory(false)}
        onOk={handleAddPromoCategory}
      >
        <Input
          placeholder="Category name"
          value={promoCategoryName}
          onChange={(e) => setPromoCategoryName(e.target.value)}
        />
      </Modal>
      <Modal
        title="Edit Promo Category"
        okText={"Update"}
        open={showModalEditCategory}
        onCancel={() => setEditModalCategory(false)}
        onOk={handleEditPromoCategory}
      >
        <p className="mx-auto" style={{ width: "fit-content" }}>
          Category Sebelumnya: <b>{editedPromoCategoryName}</b>
        </p>
        <Input
          placeholder="Category name"
          value={promoCategoryName}
          onChange={(e) => setPromoCategoryName(e.target.value)}
        />
      </Modal>
    </div>
  );
}

export default PromoCategory;
