import { Popconfirm } from "antd";

const DeleteConfirm = ({ title, onConfirm, onCancel }) => {
  return (
    <Popconfirm
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
      okText="Yes"
      cancelText="No"
    >
      <button type="button" className="btn btn-link btn-sm">
        <i className="fa fa-trash-o text-danger"/>
      </button>
    </Popconfirm>
  );
};

export default DeleteConfirm;
