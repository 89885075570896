import { useDispatch } from "react-redux";
import { handlePageChange, listsPaginate } from "../utils/helper";

const Pagination = (props) => {
    const { datas, search, listsData } = props;
    const dispatch = useDispatch();

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
                {/* Previous Button */}
                <li className={`page-item ${datas?.data?.prev_page_url || datas?.prev_page_url ? '' : 'disabled'}`}>
                    <a 
                        className="page-link" 
                        href="#" 
                        onClick={(e) =>
                            handlePageChange(
                              e,
                              parseInt(datas?.data?.current_page || datas?.current_page) - 1,
                              dispatch,
                              search,
                              listsData
                                )}
                        tabIndex="-1"
                    >
                        Previous
                    </a>
                </li>

                {/* Pagination Links */}
                {listsPaginate(datas?.data?.last_page || datas?.last_page, datas?.data?.current_page || datas?.current_page, dispatch, search, listsData)}

                {/* Next Button */}
                <li className={`page-item ${datas?.data?.next_page_url || datas?.next_page_url ? '' : 'disabled'}`}>
                    <a 
                        className="page-link" 
                        href="#" 
                        onClick={(e) =>
                            handlePageChange(
                              e,
                              parseInt(datas?.data?.current_page || datas?.current_page) + 1,
                              dispatch,
                              search,
                              listsData
                            )}
                    >
                        Next
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
