import { Modal} from "antd";

const ModalGeneric = ({
  title,
  visible,
  onOk,
  onCancel,
  options,
  onChange,
  contentType,
  datas,
  filter
}) => {
  const customCloseIcon = (
    <button style={{ cursor: "pointer", outline: "none", border: "none", backgroundColor: "transparent" }}>
      <i className="fa fa-times" aria-hidden="true"></i>
    </button>
  )

  const renderContent = () => {
    if (contentType === "single") {
      return (
        <select style={{ minWidth: '280px'}} className="form-control" onChange={onChange}  >
          {options.map((item, i) => (
            <option key={i} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      );
    } else if (contentType === "bulk") {
      const mitraCount = datas.reduce((acc, item) => {
        acc[item.mitra] = (acc[item.mitra] || 0) + 1;
        return acc;
      }, {});
      return (
        <div className="row">
          {datas?.length > 0 && (
            <p className="col-12">
              {Object.entries(mitraCount).map(([mitra, count]) => (
                <span key={mitra}>
                  {filter?.length > 0 && filter?.filter(
                      (item) => item.mitra === mitra
                    ).length} selected of {count}{" > "}
                  <strong className="text-primary">{mitra}</strong>
                  <br />
                </span>
              ))}
            </p>
          )}
          <select className="form-control" onChange={onChange}>
            {options.map((item, i) => (
              <option key={i} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      );
    }
  };

  return (
    <Modal closeIcon={customCloseIcon} style={{placeItems: "center"}} title={title} visible={visible} onOk={onOk} onCancel={onCancel}>
      {renderContent()}
    </Modal>
  );
};

export default ModalGeneric;
