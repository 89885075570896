import { useHistory, useLocation } from "react-router-dom";

export function useQueryParams() {
    const location = useLocation();
    const history = useHistory();
    const query = new URLSearchParams(location.search);

    const getQueryParam = (key) => query.get(key);
    const setQueryParam = (key, value) => {
        query.set(key, value);
        history.push({ search: query.toString() });
    };

    const resetQueryParams = () => {
        history.push({ search: "" });
    };

    return {
        getQueryParam,
        setQueryParam,
        resetQueryParams
    }
}