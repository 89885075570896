import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  roleMYCRMList,
  roleMYCRMDelete,
  roleMYCRMEdit,
} from "../../../../store/actions/menuActions";
import { Popconfirm, message, Empty } from "antd";
import TableWrapper from "../../../../components/Tables";
import Pagination from "../../../../components/Pagination";

const RoleMYCRMIndex = () => {
  const [search, setsearch] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const dataroleList = useSelector((state) => state.roleMYCRMList);
  const { role, listsRole } = dataroleList;

  const dataLogin = useSelector((state) => state.login);
  let { userInfo } = dataLogin;

  useEffect(() => {
    dispatch(roleMYCRMList( 1, search));
    localStorage.removeItem("id_role_mycrm");
  }, [dispatch]);

  useEffect(() => {
    if (!userInfo || !userInfo.permission) return;
    if (userInfo.user.roles[0].name == "Super Admin") return;
    if (!userInfo.permission.includes("role-mycrm-read")) window.location.href = process.env.REACT_APP_LINK_REDIRECT + "403";
  }, []);

  const handleEdit = (id) => {
    dispatch(roleMYCRMEdit(id)).then(() => {
      localStorage.setItem("id_role_mycrm", id);
      history.push("/role-mycrm-edit");
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(roleMYCRMList( 1, search));
  };

  const tableHead = [{ name: "No" }, { name: "Name" }, { name: "Action" }];

  const tableBody = [
    { scope: "row" },
    { render: (item) => item.name },
    {
      render: (item) => (
        <div align="center">
          {userInfo.permission.find((e) => e === "role-mycrm-edit") ? (
            <button
              onClick={() => handleEdit(item.id)}
              className="btn btn-warning btn-sm"
            >
              <i className="fa fa-pencil"></i> Edit{" "}
            </button>
          ) : null}
          {userInfo.permission.find((e) => e === "role-mycrm-delete") ? (
            <Popconfirm
              title="Are you sure to delete this role?"
              onConfirm={() =>
                dispatch(roleMYCRMDelete(item.id)).then(() =>
                  dispatch(roleMYCRMList( 1, search))
                )
              }
              onCancel={() => message.info("Canceled!")}
              okText="Yes"
              cancelText="No"
            >
              <button className="btn btn-danger btn-sm">
                <i className="fa fa-trash-o"></i> Delete{" "}
              </button>
            </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Role MYCRM</h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <br />
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  {userInfo.permission.find(
                    (e) => e === "role-mycrm-create"
                  ) ? (
                    <Link
                      to={"/role-mycrm-create"}
                      className="btn btn-info btn-sm"
                    >
                      Create
                    </Link>
                  ) : null}
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 form-group has-feedback">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search By Name"
                    style={{ float: "right", width: "50%", textOverflow: "ellipsis", paddingRight: "33px" }}
                    onChange={(e) => setsearch(e.target.value)}
                  />
                  <button
                    className="fa fa-search form-control-feedback right btn btn-link"
                    style={{
                      height: "100%",
                      margin: "auto",
                      textDecoration: "none",
                    }}
                    aria-hidden="true"
                    onClick={(e) => handleSearch(e)}
                  ></button>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>

            <div className="x_content overflow-auto">
              <TableWrapper
                tableHead={tableHead}
                tableBody={tableBody}
                datas={listsRole}
              />
              <Pagination datas={role} listsData={roleMYCRMList} search={search} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleMYCRMIndex;
