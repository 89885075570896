import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { login } from '../../store/actions/authActions'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import "./Login.css";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Login = () => {
  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const [showPassword, setshowpassword] = useState(false)

  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const dataLogin = useSelector(state => state.login)
  const { loading, error, userInfo, validation } = dataLogin
  let { from } = location.state || { from: { pathname: "/" } };
  useEffect(() => {
    if (userInfo) {
      history.push(from);
    }
  }, [userInfo, history, from])

  const handleSubmit =(e) => {
    e.preventDefault();
    dispatch(login(email, password))
  }
  
  return (
    <div>
      {/* <a className="hiddenanchor" id="signup"></a>
      <a className="hiddenanchor" id="signin"></a> */}
      <div className="Auth-form-container" style={{backgroundImage: `url("/images/mainbanner.jpg")`, backgroundRepeat:'no-repeat', backgroundSize: 'cover' , backgroundPosition: 'center' }}>
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">
              <img src='/images/lm-logo.png' className='text-center' alt='logo png'></img>
              <p className='mt-3'>Sign In To MY</p>
            </h3>
            <div className="form-group mt-3">
              <label>Email address</label>
              <input
                type="email"
                className={ validation.length !== 0 && validation['email'] ? 'form-control parsley-error' : 'form-control mt-1' }
                placeholder="Enter email"
                required="" 
                onChange={ (e) => setemail(e.target.value) } 
              />
              {
                  validation.length !== 0 
                  ?
                    <ul className="parsley-errors-list filled">
                      <li className="parsley-required">{validation['email']}</li>
                    </ul>
                  :
                    null
                }
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <div className="input-group">
                <input
                  type={showPassword? "text" : "password"}
                  className={validation.length !== 0 && validation["password"] ? "form-control parsley-error" : "form-control mt-1"}
                  placeholder="Enter password"
                  required=""
                  onChange={(e) => setpassword(e.target.value)}
                  value={password}
                />
                <button 
                  className="input-group-text mt-1"
                  style={{ 
                    border: "1px solid #ced4da",
                    background: "white",
                    cursor: "pointer",
                    borderLeft: "none"
                  }}
                  type='button'
                  onClick={() => setshowpassword(!showPassword)}
                >
                  <i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                </button>
              </div>
              
              {validation.length !== 0 ? (
                <ul className="parsley-errors-list filled">
                  <li className="parsley-required">{validation["password"]}</li>
                </ul>
              ) : null}
            </div>

            <div className="d-grid gap-2 mt-3 w-100">
              <button type="submit" className="btn btn-primary w-100" onClick={(e) => handleSubmit(e)}>
             { loading ? (
                      <Spin indicator={antIcon} />
                    ) : ('Login')}
              </button>
            </div>
            {/* <p className="text-center mt-2">
              Forgot <a href="#">password?</a>
            </p> */}
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
