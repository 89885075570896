import { Modal, Table } from "antd";

const ModalDetail = (props) => {
    const {title, visible, handleOk, handleCancel, columns, data, create, handleCreate} = props;

    return (
        <Modal style={{placeItems: "center", width: "100%", maxHeight: '85vh'}} title={title} visible={visible} onOk={handleOk} onCancel={handleCancel} footer={null}>
            {create && <button className="btn btn-info btn-sm" style={{ marginTop: "-10px", marginBottom: "10px"}} onClick={handleCreate}>{ "Create" }</button>}
           <Table columns={columns} dataSource={data} />
        </Modal>
    )
}

export default ModalDetail;