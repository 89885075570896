import { BANK_LIST_FAIL, BANK_LIST_REQUEST, BANK_LIST_SUCCESS, MITRA_CREATE_TOKEN_FAIL, MITRA_CREATE_TOKEN_REQUEST, MITRA_CREATE_TOKEN_SUCCESS, MITRA_DELETE_FAIL, MITRA_DELETE_REQUEST, MITRA_DELETE_SUCCESS, MITRA_DELETE_TOKEN_FAIL, MITRA_DELETE_TOKEN_REQUEST, MITRA_DELETE_TOKEN_SUCCESS, MITRA_DETAIL_FAIL, MITRA_DETAIL_REQUEST, MITRA_DETAIL_SUCCESS, MITRA_EDIT_FAIL, MITRA_EDIT_REQUEST, MITRA_EDIT_SUCCESS, MITRA_LIST_FAIL, MITRA_LIST_REQUEST, MITRA_LIST_SUCCESS, MITRA_SAVE_FAIL, MITRA_SAVE_REQUEST, MITRA_SAVE_SUCCESS, MITRA_SAVE_VALIDATION, MITRA_UPDATE_FAIL, MITRA_UPDATE_REQUEST, MITRA_UPDATE_SUCCESS, MITRA_UPDATE_VALIDATION } from '../constants/mitraConstants'
import { message } from 'antd'

const initialState = {
  validation: [],
}

const key = 'updatable';

export const bankListReducer = (state = {listsBank:[]}, action) => {
  switch (action.type) {
    case BANK_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case BANK_LIST_SUCCESS:
      return{ ...state, loading: false, listsBank: action.payload.data }
    case BANK_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

//=================================  Mitra  ============================================

export const mitraListReducer = (state = {mitra:[], listsMitra:[]}, action) => {
  switch (action.type) {
    case MITRA_LIST_REQUEST:
      return{ ...state, loading: true, error: [] }
    case MITRA_LIST_SUCCESS:
      return{ ...state, loading: false, mitra: action.payload.data, listsMitra: action.payload.data.data }
    case MITRA_LIST_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const mitraSaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case MITRA_SAVE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case MITRA_SAVE_SUCCESS:
      message.success({ content: 'Insert success', key });
      return{ ...state, loading: false }
    case MITRA_SAVE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case MITRA_SAVE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const mitraDetailReducer = (state = {mitra:{}}, action) => {
  switch (action.type) {
    case MITRA_DETAIL_REQUEST:
    return {...state, loading: true, error: []}
    case MITRA_DETAIL_SUCCESS: 
    return {...state, loading: false, mitra: action.payload.data}
    case MITRA_DETAIL_FAIL:
    return {...state, loading: false, error : action.payload}
    default :
    return state
  }
}

export const mitraCreateTokenReducer = (state = {}, action) => {
  switch (action.type) {
    case MITRA_CREATE_TOKEN_REQUEST: 
    message.loading('loading...', key);
    return {...state, loading: true, error: []}
    case MITRA_CREATE_TOKEN_SUCCESS:
    message.success({ content: action.payload.message, key });
    return {...state, loading: false, mitra: action.payload.data}
    case MITRA_CREATE_TOKEN_FAIL:
    message.error({ content: action.payload, key });
    return {...state, loading: false, error: action.payload}
    default :
    return state
  }
}

export const mitraDeleteTokenReducer = (state = {}, action) => {
  switch(action.type){
    case MITRA_DELETE_TOKEN_REQUEST: 
    message.loading('loading...', key);
    return {...state, loading: true, error:[]}
    case MITRA_DELETE_TOKEN_SUCCESS: 
    message.success({ content: action.payload.message, key });
    return {...state, loading: false}
    case MITRA_DELETE_TOKEN_FAIL:
    message.error({ content: action.payload, key });
    return {...state, loading: false, error: action.payload}
    default: 
    return state
  }
}

export const mitraEditReducer = (state = {mitra:{}}, action) => {
  switch (action.type) {
    case MITRA_EDIT_REQUEST:
      return{ ...state, loading: true, error: [] }
    case MITRA_EDIT_SUCCESS:
      return{ ...state, loading: false, mitra: action.payload.data }
    case MITRA_EDIT_FAIL:
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const mitraUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case MITRA_UPDATE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [], validation: [] }
    case MITRA_UPDATE_SUCCESS:
      message.success({ content: 'Update success', key });
      return{ ...state, loading: false }
    case MITRA_UPDATE_VALIDATION:
      message.error({ content: 'Please fill all required field', key });
      return{ ...state, loading: false, validation: action.payload }
    case MITRA_UPDATE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const mitraDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MITRA_DELETE_REQUEST:
      message.loading('Loading...', key);
      return{ ...state, loading: true, error: [] }
    case MITRA_DELETE_SUCCESS:
      message.success({ content: 'Delete success', key });
      return{ ...state, loading: false }
    case MITRA_DELETE_FAIL:
      message.error({ content: action.payload, key });
      return{ ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

