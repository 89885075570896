import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { officeEdit, officeUpdate } from '../../../store/actions/officeActions'
import { provinceList, citybyprovinceList, officeareaList } from '../../../store/actions/lookupActions'
import { principalbyofficeList } from '../../../store/actions/memberActions'
import { Switch } from 'antd';

const OfficeEdit = () => {
  const [name, setname] = useState('')
  const [companyname, setcompanyname] = useState('')
  const [email, setemail] = useState('')
  const [phone, setphone] = useState('')
  const [status, setstatus] = useState('')
  const [fax, setfax] = useState('')
  const [province, setprovince] = useState('')
  const [city, setcity] = useState('')
  const [address, setaddress] = useState('')
  const [mapltld, setmapltld] = useState('')
  const [description, setdescription] = useState('')
  const [joindate, setjoindate] = useState('')
  const [closedate, setclosedate] = useState('')
  const [principal, setprincipal] = useState('')
  const [officearea, setofficearea] = useState('')
  const [whatsapp, setwhatsapp] = useState('')
  const [ppc, setppc] = useState(false)

  let dispatch = useDispatch()
  let history = useHistory()
  const id = localStorage.getItem('id_office');

  useEffect(() => {
    dispatch(officeEdit(id))
    dispatch(provinceList())
    dispatch(principalbyofficeList(id))
    dispatch(citybyprovinceList(province))
    dispatch(officeareaList())
  }, [dispatch, id])

  const dataprincipalbyofficeList = useSelector(state => state.principalbyofficeList)
  const { listsPrincipalbyoffice } = dataprincipalbyofficeList

  const dataofficeareaList = useSelector(state => state.officeareaList)
  const { listsOfficeArea } = dataofficeareaList

  const dataprovinceList = useSelector(state => state.provinceList)
  const { listsProvince } = dataprovinceList

  const dataofficeUpdate = useSelector(state => state.officeUpdate)
  const { validation } = dataofficeUpdate

  const dataLogin = useSelector(state => state.login)
  const { userInfo } = dataLogin
  const updated_by = userInfo.user.id

  const dataofficeEdit = useSelector(state => state.officeEdit)
  const { office } = dataofficeEdit

  const datacitybyprovince = useSelector(state => state.citybyprovinceList)
  const { listsCity } = datacitybyprovince

  useMemo(() => {
    if (office) {
      setppc(office.ppc)
      setname(office.name)
      setcompanyname(office.company_name)
      setemail(office.email)
      setphone(office.phone)
      setstatus(office.active)
      setfax(office.fax)
      setprovince(office.province ? office.province : '')
      setcity(office.city? office.city : '')
      setaddress(office.address)
      setmapltld(office.mapLangLong)
      setdescription(office.description)
      setjoindate(office.join_date ? office.join_date : "")
      setclosedate(office.close_date ? office.close_date : "")
      setprincipal(office.id_principal)
      setofficearea(office.area)
      setwhatsapp(office.whatsapp)
    }
  }, [office])

  useEffect(() => {
        if (!userInfo || !userInfo.permission) return;
    if (userInfo.user.roles[0].name == "Super Admin") return;
    if (!userInfo.permission.includes("office-edit")) window.location.href = process.env.REACT_APP_LINK_REDIRECT + "403";
  }, []);

  const handleChangeProvince = (value) => {
    setprovince(value)
    dispatch(citybyprovinceList(value))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(officeUpdate(name,principal,companyname,email,phone,whatsapp,status,fax,province,city,address,mapltld,description,joindate,closedate, updated_by,officearea,ppc, id, history))
  }


  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Edit Office</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content overflow-auto">  
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Name *</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['name']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Company Name</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['company_name'] ? 'form-control parsley-error' : 'form-control' }
                        onChange={(e) => setcompanyname(e.target.value)}
                        value={companyname}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['company_name']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>
                
                <div className="form-group">
                  <label for="name">Principal</label>
                  <select 
                    className={ validation.length !== 0 && validation['principal'] ? 'form-control parsley-error' : 'form-control' }
                    value={principal}
                    onChange={(e) => setprincipal(e.target.value)}
                  >
                    <option disabled selected value="">Select Principal</option>
                        {
                          listsPrincipalbyoffice.map((item, index) => {
                            return(
                              <option value={item.id} key={index}>{item.name}</option>
                            )
                          })
                        }
                  </select>
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['principal']}</li>
                      </ul>
                    :
                      null
                  }
                </div>
                
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Email</label>
                      <input type="email" 
                        className={ validation.length !== 0 && validation['email'] ? 'form-control parsley-error' : 'form-control' }
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['email']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Phone</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['phone'] ? 'form-control parsley-error' : 'form-control' }
                        value={phone}
                        onChange={(e) => setphone(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['phone']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Fax</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['fax'] ? 'form-control parsley-error' : 'form-control' }
                        onChange={(e) => setfax(e.target.value)}
                        value={fax}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['fax']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Whatsapp</label>
                      <input type="text" 
                        className={ validation.length !== 0 && validation['whatsapp'] ? 'form-control parsley-error' : 'form-control' }
                        value={whatsapp}
                        onChange={(e) => setwhatsapp(e.target.value)}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['whatsapp']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>
                
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Province</label>
                      <select 
                        className={ validation.length !== 0 && validation['province'] ? 'form-control parsley-error' : 'form-control' }
                        value={province}
                        onChange={(e) => handleChangeProvince(e.target.value)}
                      >
                        <option disabled selected value="">Select Province</option>
                        {
                          listsProvince?.map((item, index) => {
                            return(
                              <option value={item.id} key={index}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      {
                            validation.length !== 0 
                            ?
                              <ul className="parsley-errors-list filled">
                                <li className="parsley-required">{validation['province']}</li>
                              </ul>
                            :
                              null
                          }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">City</label>
                      <select 
                        className={ validation.length !== 0 && validation['city'] ? 'form-control parsley-error' : 'form-control' }
                        value={city}
                        onChange={(e) => setcity(e.target.value)}
                      >
                        <option disabled selected value="">Select City</option>
                        {
                          listsCity?.map((item, index) => {
                            return(
                              <option value={item.id} key={index}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      {
                            validation.length !== 0 
                            ?
                              <ul className="parsley-errors-list filled">
                                <li className="parsley-required">{validation['city']}</li>
                              </ul>
                            :
                              null
                          }
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label for="name">Address</label>
                  <textarea
                    className={ validation.length !== 0 && validation['address'] ? 'form-control parsley-error' : 'form-control' }
                    onChange={(e) => setaddress(e.target.value)}
                    value={address}
                  ></textarea>
                  {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['address']}</li>
                          </ul>
                        :
                          null
                      }
                </div>
                
                <div className="form-group">
                  <label for="name">Map Latitude Longitude</label>
                  <input
                    type="text"
                    className={ validation.length !== 0 && validation['map_latitude_longitude'] ? 'form-control parsley-error' : 'form-control' }
                    onChange={(e) => setmapltld(e.target.value)}
                    value={mapltld}
                  ></input>
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['map_latitude_longitude']}</li>
                      </ul>
                    :
                      null
                  }
                </div>

                <div className="form-group">
                  <label for="name">Description</label>
                  <textarea
                    className={ validation.length !== 0 && validation['description'] ? 'form-control parsley-error' : 'form-control' }
                    onChange={(e) => setdescription(e.target.value)}
                  >{description}</textarea>
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['description']}</li>
                      </ul>
                    :
                      null
                  }
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Office Area</label>
                      <select 
                        className={ validation.length !== 0 && validation['office_area'] ? 'form-control parsley-error' : 'form-control' }
                        onChange={(e) => setofficearea(e.target.value)}
                        value={officearea}
                      >
                        <option disabled selected value="">Select Office Area</option>
                        {
                          listsOfficeArea.map((item, index) => {
                            return(
                              <option value={item.id} key={index}>{item.name}</option>
                            )
                          })
                        }
                          </select>
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['office_area']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Status *</label>
                      <select 
                        className={ validation.length !== 0 && validation['status'] ? 'form-control parsley-error' : 'form-control' }
                        value={status}
                        onChange={(e) => setstatus(e.target.value)}
                      >
                        <option disabled selected value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Deactive</option>
                      </select>
                      {
                            validation.length !== 0 
                            ?
                              <ul className="parsley-errors-list filled">
                                <li className="parsley-required">{validation['status']}</li>
                              </ul>
                            :
                              null
                          }
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Join Date</label>
                      <input type="date" 
                        className={ validation.length !== 0 && validation['join_date'] ? 'form-control parsley-error' : 'form-control' }
                        name="name"
                        value={joindate}
                        onChange={(e) => e ? setjoindate(e.target.value) : ""}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['join_date']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group">
                      <label for="name">Close Date</label>
                      <input type="date" 
                        className={ validation.length !== 0 && validation['close_date'] ? 'form-control parsley-error' : 'form-control' }
                        onChange={(e) =>  e ? setclosedate(e.target.value) : ""}
                        value={closedate}
                      />
                      {
                        validation.length !== 0 
                        ?
                          <ul className="parsley-errors-list filled">
                            <li className="parsley-required">{validation['close_date']}</li>
                          </ul>
                        :
                          null
                      }
                    </div>
                  </div>
                </div>
                
                <div className="form-group">
                  <label for="name"><b>PPC</b></label><br/>
                  <Switch checked={(ppc === 1 ? true : false)} onChange={(e) => (e === true ? setppc(1) : setppc(0))} />
                </div>

                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OfficeEdit
