import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { parentSave } from '../../../../store/actions/menuActions'

const ParentCreate = () => {
  const [name, setname] = useState('')
  const [icon, seticon] = useState('')
  const [access, setaccess] = useState('')

  const dispatch = useDispatch()
  const history = useHistory()

  const dataparentSave = useSelector(state => state.parentSave)
  const { validation } = dataparentSave

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(parentSave(name, icon, access, history))
  }

  useEffect(() => {
        if (!userInfo || !userInfo.permission) return;
    if (userInfo.user.roles[0].name == "Super Admin") return;
    if (!userInfo.permission.includes("parent-create")) window.location.href = process.env.REACT_APP_LINK_REDIRECT + "403";
  }, []);

  const dataLogin = useSelector((state) => state.login);
  let { userInfo } = dataLogin;

  return (
    <div className="">
      <div className="page-title">
        <div className="title_left">
          <h3>Create Parent</h3>
        </div>
      </div>
      <div className="clearfix"></div><br/>
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <h2>Form Input</h2>
              <div className="clearfix"></div>
            </div>
            
            <div className="x_content overflow-auto">  
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="form-group">
                  <label for="name">Name</label>
                  <input type="text" 
                    className={ validation.length !== 0 && validation['name'] ? 'form-control parsley-error' : 'form-control' }
                    name="name" 
                    required 
                    onChange={(e) => setname(e.target.value)} />
                  
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['name']}</li>
                      </ul>
                    :
                      null
                  }
                </div>
                <div className="form-group">
                  <label for="icon">Icon</label>
                  <input type="text" 
                    className={ validation.length !== 0 && validation['icon'] ? 'form-control parsley-error' : 'form-control' }
                    name="icon" required onChange={(e) => seticon(e.target.value)} />
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['icon']}</li>
                      </ul>
                    :
                      null
                  }
                </div>
                <div className="form-group">
                  <label for="access">Access</label>
                  <select 
                    className={ validation.length !== 0 && validation['access'] ? 'form-control parsley-error' : 'form-control' }
                    required onChange={(e) => setaccess(e.target.value)}>
                    <option disabled selected value="">Select Access</option>
                    <option value="MY">MY</option>
                    <option value="MYCRM">MYCRM</option>
                  </select>
                  {
                    validation.length !== 0 
                    ?
                      <ul className="parsley-errors-list filled">
                        <li className="parsley-required">{validation['access']}</li>
                      </ul>
                    :
                      null
                  }
                </div>
                <button type="submit" className="btn btn-primary pull-right">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ParentCreate
