import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { mitraList, mitraDelete, mitraDetail, mitraDeleteToken, mitraCreateToken } from "../../../store/actions/mitraActions";
import { Popconfirm, message, Empty } from "antd";
import { formatGUID, MaskEmail } from "../../../utils/helper";
import TableWrapper from "../../../components/Tables";
import Pagination from "../../../components/Pagination";
import ModalDetail from "../../../components/Modals/ModalDetail";

const MitraIndex = () => {
  const [search, setsearch] = useState("");
  let dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    dispatch(mitraList(1, search));
    localStorage.removeItem("id_mitra");
  }, [dispatch]);

    useEffect(() => {
          if (!userInfo || !userInfo.permission) return;
    if (userInfo.user.roles[0].name == "Super Admin") return;
      if (!userInfo.permission.includes("partners-read")) window.location.href = process.env.REACT_APP_LINK_REDIRECT + "403";
    }, []);

  const datamitraList = useSelector((state) => state.mitraList);
  const detail = useSelector((state) => state.mitraDetail);
  const {mitra: dataToken } = detail;
  const { mitra, listsMitra } = datamitraList;

  const dataLogin = useSelector((state) => state.login);
  let { userInfo } = dataLogin;

  const [showModalDetail, setShowModalDetail] = useState({
    shown: false,
    id: "",
    data: {}
  })
  const handleEdit = (id) => {
    localStorage.setItem("id_mitra", id);
    history.push("/partners-edit");
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(mitraList(1, search));
  };

  const handleDeleteToken = (id) => {
      dispatch(mitraDeleteToken(showModalDetail.id, id)).then(() => dispatch(mitraDetail(showModalDetail.id)));
  };

  const tableHead = [
    { name: "No" },
    { name: "Name" },
    { name: "Email" },
    { name: "Join Date" },
    { name: "Status" },
    { name: "Action" },
  ];

  const tableBody = [
    { scope: "row" },
    { render: (item) => item.name },
    { render: (item) => <MaskEmail item={item}/> },
    { render: (item) => item.join_date },
    {
      render: (item) => (
        <div align="center">
          <span
            className={`badge ${
              item.active ? "badge-success" : "badge-danger"
            }`}
          >
            {item.active ? "active" : "deactive"}
          </span>
        </div>
      ),
    },
    {
      render: (item) => (
        <div align="center">
          {userInfo.permission.find((e) => e === "partner-edit") ? (
            <button
              type="button"
              onClick={() => handleEdit(item.id)}
              className="btn btn-link btn-sm"
            >
              <i className="fa fa-pencil text-secondary"></i>
            </button>
          ) : null}
          {userInfo.permission.find((e) => e === "partner-delete") ? (
            <Popconfirm
              title="Are you sure to delete this parent?"
              onConfirm={() =>
                dispatch(mitraDelete(item.id)).then(() =>
                  dispatch(mitraList(1, search))
                )
              }
              onCancel={() => message.info("Canceled!")}
              okText="Yes"
              cancelText="No"
            >
              <button className="btn btn-link btn-sm">
                <i className="fa fa-trash-o text-danger"></i>
              </button>
            </Popconfirm>
          ) : null}
          <button
              type="button"
              onClick={() => setShowModalDetail({
                shown: true,
                id: item.id,
                data: item
              })}
              className="btn btn-link btn-sm"
            >
              <i className="fa fa-eye"></i>
            </button>
        </div>
      ),
    },
  ];

  const columnsDetail = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 50,
      align: 'center',
      render: (text, record, index) => index + 1
    },
    {
      title: 'Token',
      dataIndex: 'token',
      key: 'token',
      render: (_, record) => {
        return (
        <div style={{ textWrap: 'nowrap'}}>
          {formatGUID(record.token)}
        </div>
      )}
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <div style={{ textWrap: 'nowrap'}} >
          <span style={{ fontSize: '14px', fontStyle: record.is_used !== 0 ? 'normal' : 'italic', color: record.is_used !== 0 ? '#1cabe6' : '#cdcdce'}}>
            {record.is_used !== 0 ? "in use" : "not in use"}
          </span>
        </div>
      )
    },
    {
      title: 'Latest',
      dataIndex: 'latest_used',
      key: 'latest_used_at',
      render: (_, record) => (
        <div style={{ textWrap: 'nowrap' , color: record.latest_used_at !== '0000-00-00' ? '#1cabe6' : '#cdcdce'}} >
            {record.latest_used_at !== '0000-00-00' ? record.latest_used_at : "-" }
        </div>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <div style={{ textWrap: 'nowrap'}}>
          <button
            type="button"
            onClick={() => {navigator?.clipboard?.writeText(record.token).then(() => message.success("Copied!"))}}
            className="btn btn-link btn-sm"
          >
            <i className="fa fa-copy"></i>
          </button>
          <Popconfirm
              title="Are you sure to delete this token?"
              onConfirm={() => handleDeleteToken(record.id)}
              onCancel={() => message.info("Canceled!")}
              okText="Yes"
              cancelText="No"
            >
              <button className="btn btn-link btn-sm">
                <i className="fa fa-trash-o text-danger"></i>
              </button>
            </Popconfirm>
        </div>
      )
    },
  ]

  
  useEffect(() => {
    if(showModalDetail.shown) {
      dispatch(mitraDetail(showModalDetail.id))
    }
  },[showModalDetail.shown]);
  
  const handleCreate = () => {
    dispatch(mitraCreateToken(showModalDetail.id)).then(() => 
      dispatch(mitraDetail(showModalDetail.id)));
  }
  const closeModal = () => {
    setShowModalDetail({shown: false, id: "", data: {}})
  }


  return (
    <div className="">
      <ModalDetail
        title={showModalDetail.data.name}
        visible={showModalDetail.shown}
        handleOk={closeModal}
        handleCancel={closeModal}
        columns={columnsDetail}
        data={dataToken.mitra_token}
        create
        handleCreate={handleCreate}
      />
      <div className="page-title">
        <div className="title_left">
          <h3>Partner</h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <br />
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_title">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  {userInfo.permission.find((e) => e === "partners-create") ? (
                    <Link
                      to={"/partners-create"}
                      className="btn btn-info btn-sm"
                    >
                      Create
                    </Link>
                  ) : null}
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 form-group has-feedback">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search By Name"
                    style={{ float: "right", width: "50%", textOverflow: "ellipsis", paddingRight: "33px" }}
                    onChange={(e) => setsearch(e.target.value)}
                  />
                  <button
                    className="fa fa-search form-control-feedback right btn btn-link"
                    style={{
                      height: "100%",
                      margin: "auto",
                      textDecoration: "none",
                    }}
                    aria-hidden="true"
                    onClick={(e) => handleSearch(e)}
                  ></button>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>

            <div className="x_content overflow-auto">
              <TableWrapper 
                datas={listsMitra}
                tableHead={tableHead}
                tableBody={tableBody}
              />
              <Pagination
                datas={mitra}
                listsData={mitraList}
                search={search}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MitraIndex;
